import { getToken, setToken, removeToken, toLogin } from "@/utils/auth.js";
import { getUserInfoApi, logoutAPI } from "@/api/user";
import router from "@/router";
import { Message } from "element-ui";
const user = {
  state: {
    loginUserInfo: {}
  },
  mutations: {
    SAVE_USERINFO(state, info) {
      state.loginUserInfo = info;
    },
    RESET_USERINFO(state) {
      state.loginUserInfo = {};
    }
  },
  actions: {
    //获取用户信息
    async getUserInfo({ commit }) {
      try {
        let res = await getUserInfoApi();
        if (res.code === 0) {
          commit("SAVE_USERINFO", res.data);
        } else {
          return Promise.reject(res.data);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    //退出登录
    logout({ commit }, showMessage = true) {
      try {
        logoutAPI();
        //删除token
        removeToken();
        //删除本地用户信息
        commit("RESET_USERINFO");
        if (showMessage) {
          Message({
            message: "退出成功",
            type: "success"
          });
        }
        router.replace("/home");
      } catch (error) {
        console.log(error);
      }
    }
  }
};

export default user;
