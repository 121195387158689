import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/index.css";
import "./assets/css/zcRichTextView.less";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import './assets/css/element.less'
import Vant from "vant";
import "vant/lib/index.css";
import "@/router/guard";
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)
Vue.use(Vant);
Vue.use(Element);
Vue.config.productionTip = false;
Vue.prototype.$msgNum = 0;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
store.dispatch("getGlobalConfig")


