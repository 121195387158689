import axios from "./axios";
import params from "@/utils/params";

const apiURL = {
  //查询
  getDetail: params.appServiceUrl + `/ma-server/ma-api/whois`,
  //企业接入
  enterpriseTrendAPI: params.appServiceUrl + `/stat/enterpriseTrend`
};
// http://192.168.6.236/ma-server/ma-api/whois?path=MA.10000.000006.832914/40.1.2/900002

//查询
let getDetail = o => {
  return axios.get(apiURL.getDetail + "?path=" + o, o);
};

let enterpriseTrendAPI = o => {
  return axios.get(apiURL.enterpriseTrendAPI, o);
};

export { getDetail, enterpriseTrendAPI };
let appUrl = params.appServiceUrl;

// 标识总注册量、标识总解析量
export function maTotalCountAPI(data) {
  return axios.get(appUrl + "/ma-collection/stat/maTotalCount", data);
}

// 已完成二级节点接入
export function orgCountAPI(data) {
  return axios.get(appUrl + "/ma-collection/stat/orgCount?parentGnrlid=&level=3");
}

// 新闻
export function selectTitleAPI(params) {
  return axios.post(appUrl + "/title/selectTitle?pageNum=" + params.pageNum + "&pageSize=" + params.pageSize, params);
}

// 查询文章详情
export function queryByIdAPI(params) {
  return axios.post(appUrl + "/title/queryById", params);
}

// 查询公告
export function queryListAPI(params) {
  return axios.post(appUrl + "/adminMessage/queryList", params);
}

// 查询数据公示列表
export function queryDataAnnouncementsAPI(params) {
  return axios.post(appUrl + "/dataElement/queryDataAnnouncements", params);
}

// 查询公示数据详情
export function queryDataAnnounByIdAPI(data) {
  return axios.get(appUrl + "/dataElement/queryDataAnnounById/" + data);
}
// 添加公示异议
export function addOrUpdateDisputeAPI(params) {
  return axios.post(appUrl + "/dataDispute/addOrUpdateDispute", params);
}
// 上传
export function uploadAPI(params) {
  return axios.post(appUrl + "/adminFile/upload", params);
}
// 数据动态
export function queryDataDynamicsAPI(params) {
  return axios.post(appUrl + "/dataElement/queryDataDynamics", params);
}

// 查询节点下拉框,标示当前节点
export function getNodeListWithSelectInfoAPI(data) {
  return axios.get(appUrl + "/nodes/getNodeListWithSelectInfo");
}

// 首页国家节点概况
export function nodeConditionVOAPI(params) {
  return axios.post(appUrl + "/nodes/nodeConditionVO", params);
}
// 首页城市节点概况
export function nationConditionVOAPI(params) {
  return axios.post(appUrl + "/nodes/nationConditionVO", params);
}

// 消息已读
export function readMessageAPI(params) {
  return axios.post(appUrl + "/adminMessage/readMessage?messageId=" + params, params);
}

// banner 图
export function selectBannerAPI(params) {
  return axios.get(appUrl + "/banner/selectBanner", params);
}

/**
 *
 * 设置title
 *
 */
export const setTitle = str => {
  document.title = str;
  window.setTitle && window.setTitle(str);
};
