<template>
  <div class="dataElementQualityReporting">
    <div class="table-title">1.数据要素质量报告(总表)</div>
    <DataElementDetails_SummaryTable :baseInfo="baseInfo"/>
    <div class="table-title">2.数据要素质量报告(详表)</div>
    <DataElementDetails_FullList :baseInfo="baseInfo"/>
  </div>
</template>
<script>
import DataElementDetails_SummaryTable from "./DataElementDetails_SummaryTable";
import DataElementDetails_FullList from "./DataElementDetails_FullList";
export default {
  name: "DataElementQualityReporting",
  components:{
    DataElementDetails_SummaryTable,
    DataElementDetails_FullList
  },
  props: {
    baseInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {

    };
  },
  mounted(){
  },
  methods:{

  }

};
</script>
<style lang="less" scoped>
.table-title{
  margin: 30px 0;


}
.dataElementQualityReporting {
  padding-bottom: 30px;
}
</style>
