<template>
  <div class="dataDetail">
    <!-- <el-page-header content="详情" @back="$emit('back')"> </el-page-header> -->
    <div class="l-between pageHeader">
      <div class="detailText">详情</div>
      <el-button size="mini" icon="el-icon-back" @click="$emit('back')" link plain round style="margin-top: 3px">返回</el-button>
    </div>
    <div class="DataPresentationBlock">
      <DataPresentationBlock :detail="baseInfo" :scene="2" />
    </div>
    <el-tabs v-model="activeName" @tab-click="handleTabClick" style="margin-bottom: 10px" v-if="currentPage === 1">
      <el-tab-pane label="数据要素介绍" name="1"></el-tab-pane>
      <el-tab-pane label="数据要素详情" name="2"></el-tab-pane>
      <el-tab-pane label="数据要素质量报告" name="3"></el-tab-pane>
    </el-tabs>
    <div style="margin: 0 -25px">
      <DataProductIntroduction v-show="activeName === '1'" :baseInfo="baseInfo" style="padding: 25px" />
    </div>
    <DataElementDetails v-show="activeName === '2'" :baseInfo="baseInfo" />
    <DataElementQualityReporting v-show="activeName === '3'" :baseInfo="baseInfo" />
  </div>
</template>
<script>
import DataPresentationBlock from "@/views/view2024/dataPresentation/components/DataPresentationBlock.vue";
import { getDataDetail } from "@/api/dataPresentation";
import { openFile } from "@/utils";
import DataProductIntroduction from "./DataProductIntroduction.vue";
import DataElementDetails from "./DataElementDetails.vue";
import DataElementQualityReporting from "./DataElementQualityReporting.vue";
export default {
  name: "dataDetail",
  components: {
    DataPresentationBlock,
    DataProductIntroduction,
    DataElementDetails,
    DataElementQualityReporting
  },
  props: {
    baseInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      importantDataList: [
        { name: "登记节点", icon: require("@/assets/img/dataIcon_3.png"), key: "nodeName" },
        { name: "登记主体", icon: require("@/assets/img/dataIcon_5.png"), key: "firmName" },
        { name: "发布时间", icon: require("@/assets/img/dataIcon_4.png"), key: "shelfTime" },
        { name: "数据量(DRs)", icon: require("@/assets/img/dataIcon_1.png"), key: "drs" }
        // { name: '使用量(DRs)', icon: require('@/assets/img/dataIcon_2.png'), key: 'abc' },
      ],
      detail: {},
      activeName: "1"
    };
  },
  computed: {},

  methods: {
    handleTabClick() {
      window.scrollTo(0, 0, "smooth");
    }
  }
};
</script>
<style lang="less" scoped>
.pageHeader {
  align-items: center;
  .detailText {
    font-size: 18px;
  }
}
.card() {
  border-radius: 8px;
  border: 1px solid #ebeef5;
}

.dataDetail {
  .card();
  background-color: #fff;
  padding: 25px;
  min-height: calc(100vh - 170px);
}

.DataPresentationBlock {
  margin-top: 20px;
  position: relative;
  ::v-deep .el-card {
    border: none !important;
    cursor: auto;
    .disc {
      display: block;
      height: auto;
      line-height: 22px;
      font-size: 14px;
    }
    &:hover {
      box-shadow: none;
    }
  }
  .operation {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
