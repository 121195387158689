import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import privacy from '../views/privacy.vue'
import seach from '../views/view2024/seach.vue'
import service from '../views/view2024/dataPresentation'
import analyze from '../views/view2024/analyze.vue'
import applyCase from '../views/view2024/applyCase.vue'
import applyCaseDetails from '../views/view2024/applyCaseDetails.vue'

import news from '../views/pages/news.vue'
import newsDetails from '../views/pages/newsDetails.vue'
import about from '../views/pages/about.vue'
import publicity from '../views/pages/publicity.vue'
import publicityDetails from '../views/pages/publicityDetails.vue'
import demo from '../views/demo.vue'


Vue.use(VueRouter)
//将VueRouter原型上的push和replace保存一份
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

//修改VueRouter原型上的push，用于解决重复跳转报错
VueRouter.prototype.push = function(location,onSuccess,onFail){
	if(onSuccess !== undefined && onFail !== undefined){
		return originalPush.call(this,location,onSuccess,onFail)
	}else{
		return originalPush.call(this,location).catch(()=>{})
	}
}
//修改VueRouter原型上的replace，用于解决重复跳转报错
VueRouter.prototype.replace = function(location,onSuccess,onFail){
	if(onSuccess !== undefined && onFail !== undefined){
		return originalReplace.call(this,location,onSuccess,onFail)
	}else{
		return originalReplace.call(this,location).catch(()=>{})
	}
}

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/home',
    meta: {
      title: '首页'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy,
    meta: {
      title: '隐私政策'
    }
  },

  {
    path: '/seach',
    name: 'seach',
    component: seach,
    meta: {
      title: '标识解析'
    }
  },
  {
    path: '/service',
    name: 'service',
    component: service,
    meta: {
      title: '数据展示'
    }
  },
  {
    path: '/analyze',
    name: 'analyze',
    component: analyze,
    meta: {
      title: '标识解析体系'
    }
  },
  {
    path: '/applyCase',
    name: 'applyCase',
    component: applyCase,
    meta: {
      title: '应用案例'
    }
  },
  {
    path: '/applyCaseDetails',
    name: 'applyCaseDetails',
    component: applyCaseDetails,
    meta: {
      title: '解决方案'
    }
  },

  {
    path: '/news',
    name: 'news',
    component: news,
    meta: {
      title: '新闻资讯'
    }
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: newsDetails,
    meta: {
      title: '新闻资讯'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/publicity',
    name: 'publicity',
    component: publicity,
    meta: {
      title: '公示'
    }
  },
  {
    path: '/publicityDetails',
    name: 'publicityDetails',
    component: publicityDetails,
    meta: {
      title: '公示'
    }
  },
  // {
  //   path: '/demo',
  //   name: 'demo',
  //   component: demo,
  //   meta: {
  //     title: 'demo'
  //   }
  // },


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
