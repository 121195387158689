<template>
    <div class='projectList w1200'>
               <div class='projectDetails' >
               <div class='sxxq w1200'>
                   <h3>{{datas.titlePrimary}}</h3>
                      <div class='h4 '>
                            <div class='time'>{{datas.createTime}}</div>
                            <!-- <div class='xian'></div> -->
                            <!-- <div class='num'>123</div> -->
                      </div>
                      <richTextViewer :content="datas.titleDetails"/>
                    <div class='returnList'>
                        <div class='l-hbtn b btn' @click='returnListFn'>返回列表</div>
                    </div>
                    <!-- <div class='sxxq-bom'>
                        <div class='sxxq-list l-between b'>
                            <div>上一条  sssssss</div>
                            <div>2021-08-10</div>
                        </div>
                        <div class='sxxq-list l-between b'>
                            <div>下一条  sssssss</div>
                            <div>2021-08-03</div>
                        </div>
                    </div> -->
               </div>



                   </div>
          </div>

    </template>

    <script>

import richTextViewer from '@/views/components/richTextViewer'
import { queryByIdAPI } from "@/api/common";
    export default {
        props:['isshow'],
        components:{
          richTextViewer,
        },
        data () {
            return {
                datas:{},
                titleId:""
            }
        },
        watch: {
          datas(val) {
              setTimeout(()=>{
                    // if(this.datas.titleDetails)  $("#txt").html(this.datas.titleDetails)
              },100)


          }
        },

        mounted () {
            // let  u= sessionStorage.getItem("dataDetails")
            //  if(u){
            //      this.datas=JSON.parse(u)
            //  }
            this.titleId= this.$route.query.titleId
            this.getData()
        },
        methods: {
            async getData(){
                let data = {
                    titleId:this.titleId
                }
                let res =await queryByIdAPI(data)
                let titleDetails = res.data.titleDetails || ''
                titleDetails = titleDetails.replace(/\/[^<>\/]+\/adminFile\//g, `${process.env.VUE_APP_API_BASE_URL}/adminFile/`)
                this.datas={
                  ...res.data,
                  titleDetails
                }
                // this.datas.titleDetails=this.formatRichText(this.datas.titleDetails)

                // if(this.datas.fileId) this.vsrc=this.fileurl+this.datas.fileId
			},
            returnListFn(){
                // this.$parent[this.isshow]=false
                sessionStorage.setItem("isshow","否")
                setTimeout(()=>{
                    $(".boxb").animate({scrollTop: 0},200);
                },200)
                let  u  = this.$route.query.u
                this.$router.push({
                    path: '/news',
                    query:{
                         pageNo: this.$route.query.pageNo,
                        ids: this.$route.query.ids,
                        titleType:this.$route.query.titleType
                    }
                })
            },
        }
    }
    </script>

    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style lang="less" scoped>
    .w1200{
      width:936px;
      margin:0 auto;
    }
    .projectList{
        padding-bottom:50px;
    }
        .projectDetails{
            margin:60px 0 20px 0;

        }
        .sxxq{
                    background:#fff;
                    h3{
                        min-height: 37px;
                        font-size: 28px;
                        color: #333333;
                        line-height: 37px;
                        text-align: left;
                    }
                    .txt{
                        font-size: 14px;
                        color: #666666;
                        line-height: 30px;
                        padding:20px;
                    }
                    .h4{
                        font-size: 12px;
                        color: #999999;
                        margin:28px 0 16px 0;
                        .time{
                            background:url('../../assets/img/projectData_6.png') no-repeat left center;
                            background-size:13px 13px;
                            padding-left:18px;
                            margin: 15px auto;
                            margin-bottom: 20px;
                        }
                        .xian{
                            width: 2px;
                            height: 15px;
                            opacity: 0.27;
                            background:  rgba(151, 151, 151, .27);
                            margin:0 14px;
                            position: relative;
                            top:2px;
                        }
                        .num{
                            background:url('../../assets/img/projectData_7.png') no-repeat left center;
                            background-size:14px 14px;
                            padding-left:18px;
                        }
                    }
                    .returnList{
                        width: 936px;
                        height: 47px;
                        // background: #F9F9F9;
                        border-radius: 4px;
                        margin:0 auto;
                        margin-top:40px;
                        margin-bottom:60px;
                        text-align: center;
                        div{
                            margin:0 auto;
                        }
                        .btn{
                          transition: all .5s;
                          border: 1px solid #f0f0f0;
                          &:hover{
                            color: #2165fc;
                            border: 1px solid #2165fc !important;
                            background: #fff;
                          }
                        }


                    }
                }

    </style>
    <style lang='less'>
    .projectDetails{
          img{
                width:700px;
            }

        }

    </style>
