<template>
    <div class="home">
      <div class="banner">
        <!-- <img src="../assets/img/h_1.png" alt="" /> -->
        <img class="h1" src="../../assets/img2024/h_1.png" alt="" />
        <div class="banner-text l-between">
          <el-input v-model="input" placeholder="请输入您查询的标识或前缀编码" @keydown.enter.native="getDetail('')"></el-input>
          <el-button type="primary" size="small" icon="el-icon-search" style="background: #2165FC;border-radius: 0px 3px 3px 0px;width: 154px;" 
          @click="getDetail('')">查询</el-button>
          <div class="txts">查询样例：
            <span class='b blue' @click='getDetail("MA.10000.000005")'>MA.10000.000005</span>
            <span class='b blue' style='margin-left:20px' @click='getDetail("MA.10000.000005.0002/20210511000121")'>MA.10000.000005.0002/20210511000121</span>
          </div>
        </div>
        <div class="banner-list l-start">
            <img class="img" src="../../assets/img2024/s1.png" alt="" />
            <div class="txtbox">
                <div class="ma l-nowraptxt" :title='datas.queryStr?datas.queryStr:"--"'>{{datas.queryStr?datas.queryStr:"--"}}</div>
                <div class="txt">{{ datas.type=="4"?"该编码为工业互联网标识":"该编码为工业互联网节点"}}</div>
            </div>
            <div class="rig l-start">
                查询次数 <span>{{datas.queryCount?datas.queryCount:0}}</span> 次
            </div>
        </div>
      </div>
      <div class="h3">
        标识解析信息
        <div class="bj"></div>
      </div>
      <div class="const">
        <div class="look b" v-if='datas.type==4' @click="lookFn">查看标识前缀信息>></div>
        <div class="nav l-start">
            <div class="navdiv b" :class="{navdivtive:navidx==index}" v-show="(itm.id==2&&datas.type==4)||itm.id==1" v-for="(itm,index) in navList" @click="navidx=index">{{ itm.name }}</div>
        </div>
       
        <div class="box" v-if='navidx==1 '>
          
          <el-table
            :data="tableData"
            stripe
            style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
              wdith="60px"
              align="center"
             >
            </el-table-column>
            <el-table-column
              prop="name"
              label="中文名称"
              >
            </el-table-column>
            <el-table-column
              prop="code"
              label="英文名称"
              >
            </el-table-column>
            <el-table-column
              prop="type"
              label="类型">
            </el-table-column>
          </el-table>
          <!-- <div v-if="!tableData.length">
          <el-empty description="暂无数据"></el-empty>
        </div> -->
        </div>
        <div class="box" v-if='navidx==0 '>
          <div v-if="!contList.length">
          <el-empty description="暂无数据"></el-empty>
        </div>
            <div class="block l-start" :class="{bordernone:index==contList.length-1}" v-for="(itm,index) in contList">
                <div class="label">{{ itm.label }}</div>
               <div class="l-column rig">
                <div class="val">{{ itm.value }}</div>
                <div class="val2" v-if="itm.cont">
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item :title="itm.cont.name" :name="index">
                            <div v-for="item in itm.cont.list">{{item.name}}</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
               </div>
            </div>

        </div>
      </div>
     
     
     
     
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { isElementNotInViewport } from "@/utils/index.js";
  import { getDetail } from "@/api/common.js";
  
  
  export default {
    name: "Home",
    data() {
      return {
        navidx:0,
        tableData:[],
        datas:{},
        activeNames: [],
        idx:0,
        input:"",
        bannerList:[
          {name:"接入企业",key:"家",num:"193",img:require('@/assets/img2024/h_2.png')},
          {name:"注册总量",key:"万条",num:"49,110",img:require('@/assets/img2024/h_3.png')},
          {name:"解析总量",key:"万条",num:"234,110",img:require('@/assets/img2024/h_4.png')}
        ],
        navList:[
            {id:1,name:"标识解析信息"},
            {id:2,name:"元数据信息"},
        ],
        contList:[
            // {name:"标识",val:"88.111.1315/0101ZKTGA323090123001"},
            // {name:"产品类型",val:"光缆"},
            // {name:"产品名称",val:"中心管束式室外铠装光缆"},
            // {name:"产品型号",val:"ZKTGA03"},
            // {name:"生产厂商",val:"M29408",cont:{
            //     name:"关联标识：88.111.1315/M294018",
            //     list:[
            //     {name:"厂区编号：P018",val:""},
            //     {name:"负责人工号：C180685",val:""},
            //     {name:"分组编号：P018-014-006",val:""},
            //     {name:"设备编号：ER-M0025",val:""},
            //     ]
            // }},
            // {name:"生产规格",val:"-",cont:{
            //     name:"关联标识：88.111.1315/M294018",
            //     list:[
            //     {name:"厂区编号：P018",val:""},
            //     {name:"负责人工号：C180685",val:""},
            //     {name:"分组编号：P018-014-006",val:""},
            //     {name:"设备编号：ER-M0025",val:""},
            //     ]
            // }},
            // {name:"使用说明",val:"ZKTGA03中心管束式室外铠装光缆使用说明",cont:{
            //     name:"关联标识：88.111.1315/M294018",
            //     list:[
            //     {name:"厂区编号：P018",val:""},
            //     {name:"负责人工号：C180685",val:""},
            //     {name:"分组编号：P018-014-006",val:""},
            //     {name:"设备编号：ER-M0025",val:""},
            //     ]
            // }},
        ]
       
  
       
      };
    },
    mounted() {
      let that = this;
      this.input = this.$route.query.txt
      if(!this.input) return
      this.getDetail()
      // http://192.168.6.236/ma-server/ma-api/whois?path=MA.10000.000006.832914/40.1.2/900002
      // http://192.168.6.236/ma-server/ma-api/whois?path=MA.10000.000006.832914
    },
    methods: {
      lookFn(){
        let k = this.input
        let str =  k.split('/')[0]
        this.getDetail(str)
      },
      async getDetail(val){
        this.navidx=0
        this.datas={}
        this.contList=[]
        this.tableData=[]
        try {
          if(val){
            this.input=val
          }
          let res = await getDetail(this.input)
          
          if(res.code === '0'){
          this.datas =res.data 
            if(res.data.type==4){
              this.contList=res.data.data
              this.tableData= JSON.parse(res.data.metaInfo.templateRegx)

            }else {
              let o = res.data.node
              this.contList=[
                {label:"节点名称",value:o.nodename},
                {label:"IP地址",value:o.nodeip},
                {label:"端口",value:o.nodeport},
                {label:"协议",value:'HTTP'},
                {label:"节点",value:o.prefixNext},
              ]
             
              
            }
              
          }else{
            this.$message.error(res.message);
          }
        } catch (error) {
          console.log(error)
        }
      },
      seachFn(){
        this.$router.push({
          path: '',
        });
      },
      handleChange(val) {
        console.log(val);
      },
      navFn(itm,index){
        this.idx=index
  
      },
      goFn(itm){
        document.title = itm.name;
        this.$router.push({
          path: itm.url,
        });
      },
    
   
      productHandle(path) {
        this.$router.push({
          path,
        });
      },
      
   
    },
    
   
  };
  </script>
  <style lang="less" scoped>
  .home {
    background: #f8fbff;
  }
  //banner
  .banner {
    width: 100%;
    position: relative;
    height:561px;
    min-height: 561px;
    background: url("../../assets/img2024/bg.png") no-repeat top center;
    background-size:1923px 419px;
    padding-top:57px;
  
   .h1{
      margin:0 auto;
      display: block;
   }
   .banner-text{
      width: 824px;
      height: 50px;
      background: rgba(255,255,255,0.61);
      border-radius: 3px;
      border: 1px solid #2165FC;
      margin:0 auto;
      position: relative;
      /deep/.el-input input{
        border:none;
        width:100%;
        height: 48px;
        background: rgba(255,255,255,0.61);
      }
      .txts{
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: left;
        color: rgba(99, 101, 104, 0.65);
        position:absolute;
        left:0;
        top:57px;
    }
   }
  
   .banner-list{
    width: 1225px;
    height: 176px;
    background: rgba(255,255,255,0);
    box-shadow: 0px 0px 20px 14px rgba(11,25,54,0.04);
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(24px);
    margin:77px auto;
        .img{
            width:284px;
            height:155px;
            margin:11px 0 0 4px;
        }
        .txtbox{
            width: 711px;
            .ma{
                height: 41px;
                font-family: Avenir, Avenir;
                font-weight: 900;
                font-size: 30px;
                color: #2B3749;
                line-height: 41px;
                text-align: left;
                font-style: normal;
                margin:47px 0 15px 0;
            }
            .txt{
                height: 24px;
                font-family: MicrosoftYaHei;
                font-size: 18px;
                color: #67738F;
                line-height: 24px;
                text-align: left;
                font-style: normal;
            }

        }
        .rig{
            height: 24px;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            color: #67738F;
            line-height: 24px;
            text-align: left;
            font-style: normal;
            position: absolute;
            top:76px;
            right:28px;
            span{
                color:#E06D29;
                font-size: 18px;
                font-weight: bold;
                margin:0 3px;
            }
        }
   
   }
  }
  .h3{
        height: 31px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 24px;
        color: #1D2538;
        line-height: 31px;
        text-align: left;
        font-style: normal;
        position: relative;
        z-index: 1;
        width:1225px;
        margin:0 auto;
    .bj{
        width: 180px;
        height: 15px;
        background: linear-gradient( 270deg, #FFFFFF 0%, #A1BEFF 100%);
        position: absolute;
        bottom:0;
        left:0;
        z-index: -1;
    }
  }
  .const{
    width:1225px;
    margin:0 auto;
    position: relative;
    margin-bottom:50px;
    .look{
        position: absolute;
        top:5px;
        right: 0;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #6176A6;
        line-height: 21px;
    }
    .nav{
        margin-top:28px;
        .navdiv{
            width: 221px;
            height: 55px;
            background: rgba(224,229,242,0.34);
            border-radius: 8px 8px 0 0;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #4F5C7B;
            line-height: 55px;
            text-align: center;
            font-style: normal;
            margin-right: 4px;
        }
        .navdivtive{
            color: #2165FC;
            background: #FFFFFF;
        }
    }
    .box{
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        padding:30px 0 38px 48px;
        .block{
            min-height:59px;
            border-bottom: 1px solid #EBEBEB;
           
            .label{
                min-width:120px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #5B6477;
                line-height: 59px;
                text-align: left;
                font-style: normal;
            }
            .val{
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #283041;
                line-height: 21px;
                text-align: left;
                font-style: normal;
                word-break: break-all;
                padding:19px 10px;

            }
            .rig{
                flex:1;
            }
            .val2{
                width:100%;
                /deep/.el-collapse-item__header{
                    height:40px;
                    background: linear-gradient( 90deg, #ECEFF6 0%, rgba(241,242,244,0.16) 100%);
                    padding-left:10px;
                    color: #283041;
                    font-size: 16px;
                }
                /deep/.el-collapse{
                    border:none;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 10px 8px rgba(232,232,232,0.28);
                    border-radius: 4px;
                    border: 1px solid #EAEAEA;
                }
                /deep/.el-collapse-item__content{
                    padding:5px 0 10px 20px;
                    font-family: MicrosoftYaHei;
                    font-size: 16px;
                    color: #5E6F95;
                    text-align: left;
                    font-style: normal;
                    >div{
                        padding:5px 0;
                    }
                }
                /deep/.el-collapse-item__wrap{
                    border:none;
                }
            }
        }
        .bordernone{
            border-bottom:none;
        }
    }

  }
  .bom{
    width:100%;
    height:108px;
    margin-top:50px;
    background:#fff;
  }
  
  
  
  
  
  
  
  
  
  .lh1{
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 40px;
      color: #1D2538;
      line-height: 56px;
      text-align: left;
      font-style: normal;
      text-align: center;
  }
  .lh2{
    width: 100%;
    height: 26px;
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #6E7585;
    line-height: 26px;
    text-align: left;
    font-style: normal;
    margin-top:15px;
    text-align: center
  }
  
  .blue{
  color:#2579FF;
}
  
  
  
  </style>
  
  <style lang='less' scoped>
  
    
  </style>