import request from './request'
// 根据栏目id查询内容列表
export function selectTitleApi(data) {
  return request({
    url: '/title/selectTitle',
    data,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
