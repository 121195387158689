<template>
    <div class="l-column">
      <div class="banner">
        <div class="bbox">
            <div  class='h3'>标识解析体系</div>
            <div class="xian"></div>
            <div class="txt">为企业提供标识编码的发放、注册、解析等服务，帮助企业实现跨行业、跨领域的业务协同管理</div>
            </div>
        </div>
          <div class="nav">
              <div class="w1200">
                  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                  <el-menu-item :index="index" v-for="(itm,index) in navList" :key="index">{{itm.title}}</el-menu-item>
              </el-menu>
              </div>
              
          </div>
        <div class="cont" >
            <div></div>
            <div class="box1" id="box1" v-show="idx==0">
                <div class="l-h3">标识</div>
                <div class="l-txt" style="min-height:30px">标识是数字世界赋予人、事、物等对象的独一无二的数字身份证。标识有助于打通对象背后的数据链，链接物理世界和数字空间，实现数据无缝流动和交互。</div>
                <div class="l-txt" style="min-height:30px">标识可分为实体或虚拟资源两种类型，实体标识如产品、物料、设备、人员等，虚拟标识如工程、模型、算法、数据等。</div>
                <div class="l-txt" style="min-height:30px">标识对象包含内容、载体、服务三个方面，标识内容指的是标识的编码规则，标识载体分为主动标识和被动标识，标识服务是提供标识到信息的解析服务。</div>
                <img class="img" src="@/assets/img2024/service/s12.png">
            </div>

            <div class="box1 box2"  v-show="idx==1">
                <div class="l-h3">MA编码</div>
                <div class="l-txt" style="min-height:62px">MA编码为分级编码结构，分为三部分，分别是用户标识、对象类目和自定义对象个体编码。两个部分之间由“/”进行分隔，每个部分又可以使用“.”分隔为不同的节点。</div>
                <div class="b2bom">
                    <div class="b2i">
                        <img class="b2img" src="@/assets/img2024/service/a8.png">
                        <div class="b2t b2l1">MA.156.100130.7</div>
                        <div class="b2t b2l2">36.040805.01</div>
                        <div class="b2t b2l3">20220622.DM496872.569436</div>
                    </div>
                    <div class="b2block" :class="itm.cls" v-for="itm in b2List">
                        <img class="b2bimg1" :src="itm.img">
                        <img class="b2bimg2" :src="itm.bj">
                        <div class="btitle">{{ itm.title }}</div>
                        <div class="btxt">{{ itm.txt }}</div>
                    </div>
                    
                </div>
            </div>

            <div class="nav3"  v-show="idx==2">
                <div class="box1 box3">
                    <div class="l-h3">MA编码体系</div>
                    <div class="l-txt">在MA标识解析体系中，MA根节点面向全球范围不同国家、不同地区提供根区数据管理和根解析服务；MA一级节点面向一个国家或行业领域提供一级标识解析服务，以及标识备案、标识认证等管理服务；MA二级节点面向特定地区或者行业提供公共标识解析服务节点；企业节点面向企业内部提供标识编码注册和标识解析服务的标识服务节点，并通过接入标识解析二级节点对外提供解析服务。</div>
                    <div class="l-txt">MA根节点在树形层次顶层，MA根节点分配的标识前缀为“MA”。MA根节点有多个，所有MA根节点组成MA根节点联盟链。另外，每个MA根节点还可以有多个镜像MA根节点。</div>
                    <img class="img" src="@/assets/img2024/service/a1.png">
                </div>
                <div class="box3bom l-between l-wrap">
                    <div class="b3block l-start" v-for="itm in blockList">
                        <img class="b3img" :src="itm.img">
                        <dvi class="b3rig">
                            <div class="title">{{ itm.title }}</div>
                            <div class="txt">{{ itm.txt }}</div>
                        </dvi>
                    </div>
                </div>
            </div>


            <div class="box1 box4"  v-show="idx==3">
                <div class="b4div">
                <div class="l-h3">标识解析价值</div>
                <div class="l-start b4box">
                    <img class="img" src="@/assets/img2024/service/a7.png">
                    <div class="b4rig">
                        <div class="txt">工业互联网，能够让物与人、物与物展开对话，实现人、机、物互联。</div>
                        <div class="txt">工业互联网标识解析体系通过条形码、二维码、无线射频识别标签等方式赋予每一个实体或虚拟对象唯一的身份编码，同时承载相关数据信息，实现实体和虚拟对象的定位、连接和对话的新型基础设施。</div>
                        <div class="txt">标识解析体系被认为是工业互联网“基础中的基础”，是支撑工业互联网互联互通的神经枢纽，也是驱动工业互联网创新发展的关键核心设施。其作用类似于互联网领域的域名解析系统（DNS）。</div>
                    </div>
                    
                </div>
                </div>
                
                
            </div>
            

           
        </div>
        
       
      

    </div>
  </template>
  
  <script>
//   import { isElementNotInViewport } from "../../utils/index.js";
  export default {
    data() {
      return {
        activeIndex:0,
        idx:0,
        navList:[
            {title:"标识",url:"/analyze"},
            {title:"MA编码",url:"/analyze"},
            {title:"MA编码体系",url:"/analyze"},
            {title:"标识解析价值",url:"/analyze"},
        ],
        blockList:[
          {title:"一级节点",img:require('@/assets/img2024/service/a2.png'),txt:"MA根节点下属节点，一般是国家地区节点或行业领域节点，MA一级节点分配的标识前缀是“MA.国家地区代码/行业领域代码”。每个MA一级节点只能归一个MA根节点管理。"},
          {title:"二级节点",img:require('@/assets/img2024/service/a3.png'),txt:"MA一级节点下属节点，一般是地域节点或者行业节点，MA二级节点分配的标识前缀为“MA.国家地区代码/行业领域代码.地域代码/行业代码”。每个MA二级节点只能归一个MA一级节点管理。"},
          {title:"三级节点",img:require('@/assets/img2024/service/a4.png'),txt:"MA二级节点下属节点，一般是城市节点或者企业节点，MA三级节点分配的标识前缀为“MA.国家地区代码/行业领域代码.地域代码/行业代码.城市代码/企业代码”。每个MA三级只能归一个MA二级节点管理。"},
          {title:"递归节点",img:require('@/assets/img2024/service/a5.png'),txt:"递MA归节点用于寻找信息服务器的IP地址，进而获取信息服务器上的标识信息。MA递归节点和MA其他类型节点没有从属关系，不分配标识前缀。"},
        
        ],
        b2List:[
            {title:"用户标识",img:require('@/assets/img2024/service/a9.png'),bj:require('@/assets/img2024/service/a12.png'),txt:"第一个节点是根标识符前缀“MA”，是 ISO、CEN、AIM三大组织共认的全球代码；第二个节点为国家地区或领域代码，遵循ISO 3166-1:2020的要求，由 MA标识代码管理委员会分配；第三个节点是地域代码或行业代码；第四个节点为用户代码，原则上按申请顺序依次排列。",cls:"b2block1"},
            {title:"对象类目",img:require('@/assets/img2024/service/a10.png'),bj:require('@/assets/img2024/service/a14.png'),txt:"标识对象类目，可根据实际情况对标识对象进行分类管理",cls:"b2block2"},
            {title:"对象个体编码",img:require('@/assets/img2024/service/a11.png'),bj:require('@/assets/img2024/service/a13.png'),txt:"自定义标识对象个体编码，用户根据应用的需求自定义节点数量和每个节点的位数。",cls:"b2block3"},
        ]
      };
    },
    watch:{
        '$route.query.title':function(val){
            for(let i=0;i<this.navList.length;i++){
                if(this.$route.query.title==this.navList[i].title){
                    this.idx=i
                    this.activeIndex=i
                }
            }
        }
    },
    mounted() {
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
      for(let i=0;i<this.navList.length;i++){
                if(this.$route.query.title==this.navList[i].title){
                    this.idx=i
                    this.activeIndex=i
                }
            }
      
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    methods: {
      handleSelect(v){
        this.idx=v
        // let id = 'box'+(v/1+1)
        // // 假设你想滚动到页面上具有特定ID的元素的位置
        // const element = document.getElementById(id);
        
        // // 获取元素的Y坐标
        // const top = element.offsetTop;
          
        // // 滚动到该位置
        // window.scrollTo({
        //   top: top,
        //   behavior: 'smooth' // 可选，平滑滚动
        // });
      },
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? (this.animateData[i] = true)
            : (this.animateData[i] = false);
  
            if(i=='animate14' && !isElementNotInViewport(this.$refs[i])){
                setTimeout(()=>{
                  this.istwo = false
  
                },700)
            }
            if(i=='animate14' && isElementNotInViewport(this.$refs[i])){
                  this.istwo = true
            }
  
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .banner {
      width:100%;
      height:429px;
      background: url(../../assets/img2024/service/s11.png) no-repeat center center;
      background-size:1920px 429px;
      .bbox{
        width:1200px; 
        margin:0 auto;
        padding-top:103px;

      }
      .h3{
            height: 57px;
            font-family: AlimamaShuHeiTi;
            font-size: 48px;
            color: #1B2A4D;
            line-height: 67px;
            text-align: left;
            font-style: normal;
      }
      .xian{
        width: 85px;
        height: 7px;
        background: linear-gradient( 90deg, #2165FC 0%, #49C3FF 51%, #F870EE 100%);
        border-radius: 0px 3px 0px 3px;
        margin:31px 0 24px 0;
      }
      .txt{
        width: 569px;
        height: 60px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #868C99;
        line-height: 30px;
        text-align: left;
        font-style: normal;
      }
     
      
  }
  .nav{
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 6px 8px 0px rgba(12,18,34,0.05);
    /deep/.el-menu.el-menu--horizontal{
        border-bottom:none;
    }
  }
  .cont{
    .box1{
        width:100%;
        height:841px;
        background: url(../../assets/img2024/service/a6.png) no-repeat bottom center;
        background-size:1920px 441px; 
        .img{
            margin:0 auto;
            display: block;
            margin-top:36px;
        }
    }
    .box2{
        height:1200px;
        .b2bom{
            width:1131px;
            margin:0 auto;

            .b2i{
                position: relative;
                margin-bottom: 40px;
                .b2img{

                }
                .b2t{
                    width: 276px;
                    height: 59px;
                    box-shadow: 0px 0px 10px 4px rgba(9,19,71,0.1);
                    border-radius: 7px;
                    position: absolute;
                    top:53px;
                    text-align: center;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 30px;
                    color: #1D2538;
                    line-height: 59px;
                    text-align: center;
                    font-style: normal;
                }
                .b2l1{
                    left:14px
                }
                .b2l2{
                    left:336px
                }
                .b2l3{
                    width:459px;
                    left:657px
                }
            }
            .b2block{
                width: 1102px;
                height: 244px;
                box-shadow: 0px 0px 14px 10px rgba(22,37,101,0.04);
                border-radius: 8px;
                border: 1px solid;
                margin:0 auto;
                position: relative;
                margin-bottom:22px;
                z-index: 1;
                .b2bimg1{
                    width:71px;
                    height:71px;
                    margin:36px  0 0 38px;
                }
                .b2bimg2{
                    width:160px;
                    height:163px;
                    position: absolute;
                    top:23px;
                    right:25px;

                }
                .btitle{
                    margin:10px 0 6px 27px;
                    height: 33px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 24px;
                    color: #1D2538;
                    line-height: 33px;
                    text-align: left;
                    font-style: normal;
                }
                .btxt{
                    height: 56px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #6B7898;
                    line-height: 28px;
                    text-align: left;
                    font-style: normal;
                    padding:0 25px 0 27px;
                }
            }
            .b2block1{
                background: linear-gradient( 180deg, #F4F7FF 0%, #FFFFFF 100%);
                border-image: linear-gradient(180deg, rgba(204, 221, 255, 1), rgba(255, 255, 255, 0)) 1 1;
            }
            .b2block2{
                background: linear-gradient( 180deg, #F4FDFC 0%, #FFFFFF 100%);
                border-image: linear-gradient(180deg, rgba(192, 238, 235, 1), rgba(255, 255, 255, 0)) 1 1;
            }
            .b2block3{
                background: linear-gradient( 180deg, #FEFAF9 0%, #FFFFFF 100%);
                border-image: linear-gradient(180deg, rgba(255, 201, 186, 1), rgba(255, 255, 255, 0)) 1 1;
            }
        }
    }
   .nav3{
    .box3{
        height:982px;
        .img{
            margin-top:26px;
        }
        
    }
    .box3bom{
            width:1225px;
            height:480px;
            margin:0 auto;
            padding-top:50px;
            .b3block{
                width: 604px;
                height: 173px;
                background: #F7F8FB;
                border-radius: 8px;
                padding:26px;
                &:hover{
                    width: 604px;
                    height: 173px;
                    background: #F7F8FB;
                    box-shadow: 0px 0px 20px 13px rgba(10,12,53,0.07);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                }
                .b3img{
                    width:49px;
                    height:48px;
                    margin-right: 18px;
                }
                .title{
                    height: 28px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    font-size: 20px;
                    color: #1D2538;
                    line-height: 28px;
                    font-style: normal;
                    margin:10px 0 9px 0;
                }
                .txt{
                    width: 472px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #6D727D;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                }


            }
        }
   }
   .box4{
        height:605px;
       
        .b4div{
        width:1112px;
        margin:0 auto;

        }
        .b4box{
            margin-top:15px;
        }
        img{
            width:436px;
            height:446px;
            margin:0px 56px 0 0 !important;
        }
        .b4rig{
            margin-top:78px;
            .txt{
                width: 607px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #8890A2;
                line-height: 30px;
                text-align: left;
                font-style: normal;
                margin-bottom:29px;
            }
        }
   }
    
    
  }
  
  </style>
  