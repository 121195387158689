<template>
  <el-form label-width="100px" size="small">
    <!-- <el-form-item label="价格:">
      <el-select v-model="search.price" placeholder="全部" class="w100" clearable>
        <el-option
          v-for="item in priceList"
          :key="item.code"
          :label="item.name"
          :value="item.code">
        </el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="所属节点:" v-if="isNation">
      <el-select v-model="search.nodeIds" placeholder="请选择" class="w100" clearable filterable multiple  >
        <el-option v-for="item in nodeList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="数据覆盖区域:">
      <el-cascader clearable class="w100" placeholder="全部地区" v-model="search.district" :options="areaList" :props="areaProps"></el-cascader>
    </el-form-item>
    <el-form-item label="发布时间:">
      <el-date-picker v-model="search.dateRange" type="daterange" align="right" range-separator="至" value-format="yyyy-MM-dd"
        start-placeholder="开始时间" end-placeholder="结束时间" class="w100">
      </el-date-picker>
    </el-form-item>
    <el-form-item label="数据关键字:">
      <el-select v-model="search.keywords" placeholder="请选择" class="w100" clearable filterable multiple>
        <el-option v-for="item in keywordList" :key="item.code" :label="item.name" :value="item.name">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="行业:" v-if="showAdvancedFormIndustry">
      <el-cascader
      v-model="search.industryClassArrs"
      style="width: 100%;"
      :options="industryList"
      :props="{
        checkStrictly: true,
        multiple: true,
        label:'name',
        value:'code',
        children:'children'
      }"
      clearable>
    </el-cascader>
    </el-form-item>

    <div class="footer">
      <el-button type="primary" style="width: 100px;" @click="handleSearch" :loading="loading">确定</el-button>
      <el-button icon="el-icon-refresh-left" @click="handleReset">重置</el-button>
    </div>
  </el-form>
</template>
<script>
import { queryDict } from '@/api/dic'
import { getNodeListWithSelectInfoAPI } from "@/api/common";
export default {
  name: "AdvancedFilterForm",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    showAdvancedFormIndustry:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keywordList: [],
      nodeList:[],
      industryList:[],
      priceList:[
        {name:'收费',code:1},
        {name:'免费',code:2},
      ],

      search: {
        price:'',
        keywords:[],
        dateRange:[],
        district:[],
        nodeIds:[],
        industryClassArrs:[]
      },
      areaList: [],
      areaProps: {
        label: 'name',
        value: 'code',
        checkStrictly: true,
        multiple: true
      }
    }
  },
  mounted() {
    this.getKeywordList()
    this.getAreaList()
    this.getNodeList()
    this.getIndustryList()
  },
  computed:{
    isNation(){
      return process.env.VUE_APP_APPTYPE === '国家'
    }
  },
  watch:{
    showAdvancedFormIndustry(val){
      this.search.industryClassArrs = []
    }
  },
  methods: {
    //获取地区枚举
    async getNodeList() {
      try {
        let res = await getNodeListWithSelectInfoAPI()
        this.nodeList = res.data
      } catch (error) {
        console.log(error)
      }
    },

    //获取关键字枚举
    async getKeywordList() {
      try {
        let res = await queryDict(5)
        this.keywordList = res.data
      } catch (error) {
        console.log(error)
      }
    },
    //获取地区枚举
    async getAreaList() {
      try {
        let res = await queryDict(2)
        this.clearChildren(res.data)
        this.areaList = res.data
      } catch (error) {
        console.log(error)
      }
    },

    //获取排序方式枚举
    async getSortList() {
      try {
        let res = await queryDict(1)
        this.sortList = res.data
      } catch (error) {
        console.log(error)
      }
    },
    //获取行业树
    async getIndustryList() {
      try {
        let res = await queryDict(3)
        this.clearChildren(res.data)
        this.industryList = res.data
      } catch (error) {
        console.log(error)
      }
    },

    clearChildren(list) {
      list.forEach(item => {
        if (item.children && item.children.length === 0) {
          item.children = null
        }
        if (item.children) {
          this.clearChildren(item.children)
        }
      })
    },
    handleReset() {
      this.resetSearch()
      // this.$emit('reset', getParams())
    },
    handleSearch() {
      console.log(this.search)
      this.$emit('search', this.getParams())
    },
    resetSearch(){
      this.search = {
        price:'',
        keywords:[],
        dateRange:[],
        district:[],
        nodeIds:[],
        industryClassArrs:[]
      }
    },
    getParams(){
      let params = {
        ...this.search,
        startTime:(this.search.dateRange && this.search.dateRange[0]) || undefined,
        endTime:(this.search.dateRange && this.search.dateRange[1]) || undefined,
      }
      delete params.dateRange
      return params
    }

  }

}
</script>
<style lang="less" scoped>
.w100 {
  width: 100% !important;
}

.footer {
  text-align: right;
}
</style>
