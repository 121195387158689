<template>
  <div class="msg">
    <div class="head">
      <div class="index l-between" v-if="!isDetails">
        <div class="h1">我的信息</div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="itm.label" :name="itm.name" v-for="itm in navList" :key="itm.name"></el-tab-pane>
        </el-tabs>
        <div class="ipt l-start">
          <el-input
            placeholder="请输入关键字"
            class="iiup"
            style="width: 150px; margin-right: 10px; position: relative; top: -8px"
            prefix-icon="el-icon-search"
            @keydown.enter.native="getList"
            v-model="txt"
          >
          </el-input>
          <el-date-picker
            @keydown.enter.native="getList"
            @change="getList"
            v-model="times"
            style="width: 220px; height: 34px; position: relative; top: 8px"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :size="size"
          />
        </div>

        <i class="el-icon-close b close" @click="closeBox"></i>
      </div>
      <div class="details l-start" v-if="isDetails">
        <i
          class="el-icon-back b back"
          @click="
            getList();
            isDetails = false;
          "
        ></i>
        <div class="h1">信息详情</div>
        <i class="el-icon-close b close" @click="closeBox"></i>
      </div>
    </div>
    <div class="box l-column" v-if="!isDetails">
      <div class="" style="flex: 1; overflow-y: auto">
        <el-empty description="暂无数据" v-if="!tableDatas.list.length" />
        <div class="block l-start b" v-for="itm in tableDatas.list" @click="rowFn(itm)">
          <img class="img" v-if="itm.label == 4" src="@/assets/img/msg2.png" alt="" />
          <img class="img" v-if="itm.label == 6" src="@/assets/img/msg1.png" alt="" />
          <div class="rig">
            <div class="title l-start">
              {{ itm.title }}
              <div class="new" v-if="itm.isRead == 0">new</div>
            </div>
            <div class="time">{{ itm.createTime }}</div>
            <!-- isRead 0 未读 1 已读 -->
          </div>
        </div>
      </div>

      <div class="bombox" v-if="tableDatas.list.length != 0">
        <el-pagination
          style="padding-right: 20px"
          background
          :page-sizes="[25, 50, 75, 100]"
          :page-size="pageSize"
          @current-change="currentChangeFn"
          @size-change="handleSizeChange"
          :current-page.sync="pageNo"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableDatas.totalRow"
        >
        </el-pagination>
      </div>
    </div>
    <div class="box box2 l-start" v-if="isDetails">
      <img class="img" v-if="datas.label == 4" src="@/assets/img/msg2.png" alt="" />
      <img class="img" v-if="datas.label == 6" src="@/assets/img/msg1.png" alt="" />
      <div class="rig">
        <div class="title">{{ datas.title }}</div>
        <div class="time">{{ datas.createTime }}</div>
        <div class="txt" v-html="datas.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { selectReceptionTitle } from "@/api/common";
import { queryListAPI, readMessageAPI } from "@/api/common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeName: "",
      txt: "",
      datas: {},
      isDetails: false,
      navList: [
        { label: "全部信息", name: "" },
        { label: "系统信息", name: "6" },
        { label: "通知公告", name: "4" }
      ],
      tableDatas: {},
      pageNum: 1,
      pageSize: 25,
      times: []
    };
  },
  computed: {
    ...mapGetters(["loginUserInfo", "msgNum"])
  },
  watch: {
    $route(v) {
      // this.routePath = v.path;
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    rowFn(itm) {
      this.datas = itm;
      this.readMessage(itm.messageId, itm);
      this.isDetails = true;
    },
    readMessage(n, itm) {
      readMessageAPI(n).then(res => {
        if (itm.isRead == 0) {
          let k = this.msgNum + 1;
          this.$store.commit("SAVE_MSGNUM", k);
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab.name);
      this.getList();
    },
    closeBox() {
      this.$emit("closeFn");
    },
    currentChangeFn(val) {
      this.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },

    getList() {
      let o = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        titleType: this.activeName == "0" ? "" : this.activeName,
        queryContent: this.txt
      };
      if (this.times.length) {
        o.startTime = this.times[0];
        o.endTime = this.times[1];
      }
      queryListAPI(o).then(res => {
        if (res.code == 0) {
          this.tableDatas = res.data;
        }
      });
    },

    navHandle(item) {
      document.title = item.title;
      this.$router.push({
        path: item.url
      });
    }
  }
};
</script>

<style lang="less" scoped>
.msg {
  position: absolute;
  top: 89px;
  right: 42px;
  z-index: 10;
  width: 974px;
  min-height: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 6px rgba(41, 39, 88, 0.13);
  border-radius: 4px;
  border: 1px solid #dcdee1;
  .head {
    height: 66px;
    line-height: 66px;
    border-bottom: 1px solid #d6d6d6;
    .h1 {
      height: 26px;
      font-family: MicrosoftYaHei;
      font-size: 20px;
      color: #2e2e2e;
      line-height: 26px;
      text-align: left;
      font-style: normal;
      padding: 18px 0 0 28px;
    }
    .ipt {
      margin-right: 54px;
      position: relative;
      top: 7px;
      :deep(.el-input__inner),
      :deep(.el-range-input) {
        font-size: 12px;
      }
      :deep(.el-range-separator) {
        display: block;
        text-align: center;
        width: 22px;
        font-size: 12px;
      }
      :deep(.el-range-separator),
      :deep(.el-input__icon) {
        position: relative;
        top: -3px;
      }
      .iiup :deep(.el-input__icon) {
        top: -5px;
      }
    }
    /deep/.el-input__inner {
      width: 100%;
      height: 32px;
      line-height: 32px;
      background: #ffffff;
      border-radius: 16px;
    }
    /deep/.el-tabs__item {
      font-size: 16px;
    }
    :deep(.el-tabs) {
      position: relative;
      top: 0px;
      left: -44px;
    }
    :deep(.el-tabs__item) {
      font-size: 14px;
    }
    .close {
      font-size: 16px;
      position: absolute;
      top: 26px;
      right: 21px;
    }

    .details {
      .back {
        font-size: 30px;
        color: #b6bbc6;
        margin: 20px 0 0 28px;
      }
      .h1 {
        padding-left: 5px;
      }
    }
  }
  .box {
    width: 100%;
    padding-left: 27px;
    height: 400px;
    .bombox {
      text-align: center;
      margin: 20px 0 30px 0;
    }
    .block {
      height: 83px;
    }
    .img {
      width: 46px;
      height: 45px;
      min-width: 46px;
      margin: 16px 15px 0 0;
    }
    .rig {
      flex: 1;
      padding: 15px 0;
      border-bottom: 1px dashed #dfe7f1;
      .title {
        height: 21px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #313e5d;
        line-height: 21px;
        font-style: normal;
      }
      .time {
        height: 20px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #a9abb6;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-top: 9px;
      }
    }
  }
  .box2 {
    .txt {
      width: 784px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #687186;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      margin-top: 12px;
    }
    .rig {
      border: none;
    }
  }
}
.new {
  width: 27px;
  height: 14px;
  background: #ef532b;
  border-radius: 7px;
  font-family: MicrosoftYaHei;
  font-size: 10px;
  color: #ffffff;
  line-height: 13px;
  text-align: center;
  font-style: normal;
  margin: 4px 0 0 10px;
}
</style>
