<template>
  <div class="dataPresentation" id="myDiv">
    <div
      class="wrap dataPresentationimg"
      :style="`padding-left: ${isShowDetail ? 0 : 260}px;width: ${isShowDetail ? 1200 : 1350}px;`"
    >
      <div class="left l-start" v-show="!isShowDetail" @mouseleave="hideIndustryFilter">
        <ul class="plateList" :style="`height:${plateListHeight}px;z-index:100;position: relative;`" v-loading="plateListLoading">
          <li
            class="plate l-between"
            v-for="item in industryList"
            :key="item.code"
            @mouseenter.stop="tempSelect(item, 0)"
            :class="{
              temp_selected: item.code === (tempSelectedIndustryList[0] && tempSelectedIndustryList[0].code),
              plate_active: item.code === (selectedIndustryList[0] && selectedIndustryList[0].code)
            }"
            @click="handleChooseIndustry(item)"
          >
            <span class="plate-text">{{ item.name }}</span>
            <span class="el-icon-arrow-right" v-if="item.children && item.children.length"></span>
          </li>
        </ul>
        <transition name="fade">
          <div class="plateListCascader l-start" v-show="tempSelectedIndustryList.length">
            <template v-for="(item, index) in tempSelectedIndustryList">
              <ul class="plateList plateList2" :style="`height:${plateListHeight}px;z-index:100;position: relative;`" :key="item.code" v-if="item.children && item.children.length">
                <li
                  class="plate l-between"
                  :class="{
                    temp_selected:
                      subItem.code === (tempSelectedIndustryList[index + 1] && tempSelectedIndustryList[index + 1].code),
                    plate_active: subItem.code === (selectedIndustryList[index + 1] && selectedIndustryList[index + 1].code)
                  }"
                  v-for="subItem in item.children"
                  :key="subItem.code"
                  @mouseenter.stop="tempSelect(subItem, index + 1)"
                  @click="handleChooseIndustry(subItem)"
                >
                  <span class="plate-text">{{ subItem.name }}</span>
                  <span class="el-icon-arrow-right" v-if="subItem.children && subItem.children.length"></span>
                </li>
              </ul>
            </template>
          </div>
        </transition>
      </div>
      <div class="right">
        <div v-show="!isShowDetail">
          <div class="right-top">
            <div class="banner">
              <img class="banner_img" src="@/assets/img/mall_1.png" alt="" />
              <!-- <span class="banner_text_1">
                <img v-if="isCity" src="@/assets/img/l1.png" alt="" />
                <img v-if="!isCity" src="@/assets/img/l2.png" alt="" />
              </span> -->
              <!-- <span class="banner_text_2">数据展示</span> -->
            </div>
            <div class="searchTab l-between">
              <div class="searchTab_left">
                <ul class="subPlateList l-start">
                  <li
                    class="subPlate"
                    :class="{ subPlate_active: search.dataType === item.code }"
                    v-for="item in dataTypeList"
                    :key="item.code"
                    @click="handleDataTypeChange(item.code)"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
              <div class="searchTab_right l-start">
                <div class="order-wrap advancedFilterBtn">
                  <img
                    src="@/assets/img/sort.png"
                    class="sortTypeIcon"
                    :class="{ sortAsc: search.sortType === 'asc' }"
                    @click="handleSortTypeChange"
                  />
                  <el-dropdown @command="handleSelectSort">
                    <span class="el-dropdown-link"> {{ sortShowName }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="(item, index) in sortList" :key="index" :command="item.code">{{
                        item.name
                      }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="search">
                  <div class="searchIcon">
                    <i class="el-icon-search"></i>
                  </div>
                  <el-input
                    v-model.trim="queryCount"
                    placeholder="请输入要素名称 / 登记主体 / 编码"
                    @keyup.native.enter="handleKeyWordSearch"
                    style="width: 280px"
                  >
                  </el-input>
                </div>
                <el-popover
                  placement="bottom-end"
                  width="500"
                  trigger="manual"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
                  v-model="advancedFilterVisible"
                >
                  <div slot="reference" class="advancedFilterBtn linkBtn" @click="advancedFilterVisible = !advancedFilterVisible">
                    <img src="@/assets/img/p1.png" />
                    <span style="margin: 0 5px">高级搜索</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <AdvancedFilterForm
                    :loading="loading"
                    @search="handleAdvancedSearch"
                    :showAdvancedFormIndustry="showAdvancedFormIndustry"
                  />
                </el-popover>
              </div>
            </div>
          </div>
          <div class="breadcrumb">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              v-if="!(selectedIndustryList.length === 1 && selectedIndustryList[0].name === '全部')"
            >
              <el-breadcrumb-item
                v-for="(item, index) in selectedIndustryList"
                :key="index"
                :class="{
                  active_breadcrumb: index === selectedIndustryList.length - 1
                }"
                @click.native="handleChooseIndustry_breadcrumb(index)"
              >
                {{ item.name }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div v-loading="loading || plateListLoading" element-loading-text="加载中...">
            <div class="list-wrap">
              <template v-if="list.length">
                <DataPresentationBlock
                  class="list-item"
                  v-for="item in list"
                  :key="item.dataId"
                  :detail="item"
                  @click.native="toDetail(item)"
                />
              </template>
              <div v-else class="empty-wrap">
                <el-empty :image-size="200" :image="require('@/assets/img/empty.png')"></el-empty>
              </div>
            </div>
          </div>
          <div class="pagination-wrap">
            <el-pagination
              :current-page="currentPage"
              :page-sizes.sync="pageSizes"
              :page-size.sync="pageSize"
              :total="total"
              class="p-bar"
              background
              layout="total,prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :disabled="loading"
            />
          </div>
        </div>
        <div v-if="isShowDetail">
          <Detail :baseInfo="curData" @back="handleDetailBack" :currentPage="currentPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataPresentationBlock from "@/views/view2024/dataPresentation/components/DataPresentationBlock.vue";
import Detail from "@/views/view2024/dataPresentation/components/Detail.vue";
import AdvancedFilterForm from "@/views/view2024/dataPresentation/components/AdvancedFilterForm.vue";
import TableMixin from "@/mixins/table";
import { queryDict } from "@/api/dic";
import { cloneDeep } from "lodash";

export default {
  components: {
    DataPresentationBlock,
    Detail,
    AdvancedFilterForm
  },
  mixins: [TableMixin],
  data() {
    return {
      plateListHeight: window.innerHeight - 93,
      isCity: process.env.VUE_APP_APPTYPE == "国家" ? false : true,
      plateListLoading: false,
      pageSize: 10,
      listApiName: "dataPresentation1",
      showAdvancedFormIndustry: true,
      curData: {},
      isShowDetail: false,
      industryList: [],
      selectedIndustryList: [],
      tempSelectedIndustryList: [],
      dataTypeList: [],
      advancedFilterVisible: false,
      keywordList: [],
      queryCount: "",
      sortList: [
        { name: "发布时间", code: "shelfTime" }
        // {name:'数据使用量',code:'useTotal'},
        // {name:'评价',code:'collectTotal'},
      ],
      search: {
        dataType: 0,
        queryCount: "",
        sort: 2,
        sortName: "shelfTime",
        sortType: "desc"
      },
      heightWithPaddingAndBorder: 0
    };
  },
  watch: {},
  mounted() {
    var that = this;
    this.getIndustryList();
    this.getDataTypeList();

    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", function () {
      that.leftH();
    });
  },
  computed: {
    sortShowName() {
      return this.sortList.find(item => item.code === this.search.sortName).name;
    }
  },
  methods: {
    leftH() {
      setTimeout(() => {
        // 在此处添加滚动条改变时的处理逻辑
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // 获取div元素
        let div = document.getElementById("myDiv");
        // 获取元素的高度，包括内边距和边框
        let heightWithPaddingAndBorder = div.offsetHeight;
        this.heightWithPaddingAndBorder = heightWithPaddingAndBorder;

        let viewportHeight = window.innerHeight;
        let h = heightWithPaddingAndBorder - viewportHeight - scrollTop;
        let num = h > 60 ? 0 : 60 - h;
        let ph = h >= -7 ? window.innerHeight - 93 - num : window.innerHeight - 93;
        console.log(h, "---");
        this.plateListHeight = ph;
      }, 10);
    },
    handleResize() {
      // this.plateListHeight = window.innerHeight - 93;
      this.leftH();
    },
    toDetail(row) {
      console.log(row);
      this.curData = row;
      this.isShowDetail = true;
      this.advancedFilterVisible = false;
    },
    handleDetailBack() {
      this.isShowDetail = false;
      this.curData = {};
    },
    //获取行业树
    async getIndustryList() {
      try {
        this.plateListLoading = true;
        let res = await queryDict(3);
        this.plateListLoading = false;
        let allItem = {
          name: "全部",
          code: 0,
          children: []
        };
        this.industryList = [allItem, ...res.data];
        this.tempSelectedIndustryList = [allItem];
        this.handleChooseIndustry();
      } catch (error) {
        this.plateListLoading = false;
        console.log(error);
      }
    },
    //获取登记类型
    async getDataTypeList() {
      try {
        let res = await queryDict(4);
        let allItem = {
          name: "全部",
          code: 0,
          children: null
        };
        this.dataTypeList = [allItem];
        // this.dataTypeList = [allItem, ...res.data]
      } catch (error) {
        console.log(error);
      }
    },

    //临时选中行业
    tempSelect(item, index) {
      this.tempSelectedIndustryList.splice(index, 1, item);
      this.tempSelectedIndustryList.splice(index + 1);
    },
    // 隐藏行业筛选
    hideIndustryFilter() {
      this.tempSelectedIndustryList = [];
    },
    handleChooseIndustry_breadcrumb(index) {
      if (index >= this.selectedIndustryList.length - 1 || this.loading) return;
      let list = [...this.selectedIndustryList];
      list.splice(index + 1);
      this.tempSelectedIndustryList = list;
      this.handleChooseIndustry();
    },
    //选择行业
    handleChooseIndustry() {
      if (this.loading) return;
      let tempSelectedCodeStr = this.tempSelectedIndustryList.map(item => item.code).join(",");
      let selectedCodeStr = this.selectedIndustryList.map(item => item.code).join(",");
      if (tempSelectedCodeStr === selectedCodeStr) {
        return;
      }

      this.selectedIndustryList = this.tempSelectedIndustryList;
      this.tempSelectedIndustryList = [];
      this.search.industryClassArrs = [];
      if (this.selectedIndustryList.length === 1 && this.selectedIndustryList[0].code === 0) {
        this.showAdvancedFormIndustry = true;
      } else {
        this.showAdvancedFormIndustry = false;
      }
      this.search = {
        ...this.search,
        industryClassAId: this.selectedIndustryList[0] && this.selectedIndustryList[0].code,
        industryClassBId: this.selectedIndustryList[1] && this.selectedIndustryList[1].code,
        industryClassCId: this.selectedIndustryList[2] && this.selectedIndustryList[2].code,
        industryClassDId: this.selectedIndustryList[3] && this.selectedIndustryList[3].code
      };
      this.currentPage = 1;
      this.getList(this.leftH);
    },
    //选择登记类型
    handleDataTypeChange(code) {
      if (this.loading) return;
      if (code === this.search.dataType) return;
      this.search.dataType = code;
      this.getList(this.leftH);
    },
    //选择排序方式
    handleSelectSort(params) {
      if (this.loading) return;
      if (this.search.sortName === params) return;
      this.search.sortName = params;
      this.currentPage = 1;
      this.getList(this.leftH);
    },
    //改变排序方式正序倒序
    handleSortTypeChange() {
      if (this.loading) return;
      this.search.sortType = this.search.sortType === "asc" ? "desc" : "asc";
      this.currentPage = 1;
      this.getList(this.leftH);
    },
    //高级筛选
    handleAdvancedSearch(params) {
      this.search = {
        ...this.search,
        ...params
      };
      this.currentPage = 1;
      this.getList(this.leftH);
      this.advancedFilterVisible = false;
    },
    handleKeyWordSearch() {
      this.search.queryCount = this.queryCount;
      this.currentPage = 1;
      this.getList(this.leftH);
    }
  }
};
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.dataPresentation {
  background-color: #f7f8fa;
}

.card() {
  border-radius: 8px;
  border: 1px solid #ebeef5;
}

.wrap {
  width: 1350px;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;

  .left {
    position: fixed;
    top: 90px;
    left: 50%;
    z-index: 2;
    border-radius: 8px;
    margin-left: -676px;
    z-index: 2;
    .card();

    .plateList {
      width: 250px;
      overflow-y: auto;
      background-color: #fff;

      .plate {
        cursor: pointer;
        align-items: center;
        padding: 15px 20px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #767676;
        line-height: 19px;

        &:hover {
          background: #f5f7fa;
        }

        &.temp_selected {
          background: #f5f7fa;
        }
      }

      .plate-text {
        margin-right: 10px;
      }

      .plate_active {
        background: #b3d8ff52 !important;
        font-weight: 600;
        color: #007aff;
      }
    }

    .plateListCascader {
      background-color: #fff;
      border-radius: 0 8px 8px 0;
    }
  }

  .right {
    padding-bottom: 80px;
    // background-color: green;
    flex: 1;
    height: 100%;

    .right-top {
      .card();
      overflow: hidden;
    }

    .banner {
      position: relative;

      .banner_img {
        display: block;
        width: 1090px;
        height: 166px;
        object-fit: cover;
        background-color: #fafafa;
        position: relative;
        left: -1px;
      }

      .banner_text_1 {
        font-family: MicrosoftYaHei;
        font-size: 20px;
        color: #6186ef;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        position: absolute;
        top: 74px;
        left: 60px;
      }

      .banner_text_2 {
        font-family: AlimamaShuHeiTi;
        font-size: 40px;
        color: #5770b7;
        line-height: 56px;
        text-align: left;
        font-style: normal;
        position: absolute;
        top: 100px;
        left: 60px;
      }
    }

    .searchTab {
      height: 60px;
      background-color: #fff;
      padding: 0 15px;

      .searchTab_left {
        align-self: flex-end;

        .subPlateList {
          .subPlate {
            height: 60px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-size: 15px;
            color: #666;
            line-height: 70px;
            margin-left: 20px;
            cursor: pointer;

            &:hover {
              color: #007aff;
            }

            &.subPlate_active {
              color: #007aff;
              border-bottom: 2px solid #007aff;
              font-weight: bold;
            }
          }
        }
      }

      .searchTab_right {
        align-self: center;
        align-items: center;
        .order-wrap {
          margin-right: 10px;
          .sortTypeIcon {
            margin-right: 8px;
            transform: rotate(180deg);
            transition: all 0.1s ease-in-out;
          }
          .sortAsc {
            transform: rotate(0deg);
          }
        }
        .search {
          border: 1px solid #dcdfe6;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 10px;
          transition: border 0.2s ease-in-out;
          ::v-deep .el-input__inner {
            border: none !important;
          }
        }

        .advancedFilterBtn {
          margin-left: 10px;
          border: 1px solid #dcdfe6;
          border-radius: 8px;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
        }
        .linkBtn {
          color: #007aff;
          border: none;
          padding: 0;
        }
      }
    }

    .breadcrumb {
      padding: 10px 15px;
      display: flex;
      justify-content: flex-end;

      ::v-deep .el-breadcrumb {
        font-size: 12px !important;
        .el-breadcrumb__item {
          cursor: pointer;
          &:hover {
            .el-breadcrumb__inner {
              color: #333;
              font-weight: bold;
            }
          }
        }
        .el-breadcrumb__inner {
          font-size: 12px !important;
        }
      }
      .active_breadcrumb {
        ::v-deep .el-breadcrumb__inner {
          color: #333 !important;
          font-weight: 600 !important;
        }
      }
    }

    .list-wrap {
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      min-height: calc(100vh - 580px);

      .empty-wrap {
        width: 100%;
        background-color: #fff;
        align-self: stretch;
      }

      .list-item {
        width: calc(50% - 5px);
        margin-bottom: 10px;
        flex-grow: 0;

        &:nth-of-type(2n + 1) {
          margin-right: 10px;
        }
      }
    }

    .pagination-wrap {
      text-align: right;
    }
  }
}
</style>
