const TokenKey = process.env.VUE_APP_TOKEN_KEY;
export function getToken() {
  return sessionStorage.getItem(TokenKey) || localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  sessionStorage.removeItem(TokenKey);
  localStorage.removeItem(TokenKey);
}

export function toLogin(t, n) {
  let u = "";
  let url = window.location.href;
  if (n && n.indexOf("/flow") != -1) url += n;
  if (t == "注册") {
    u = `${process.env.VUE_APP_ADMINPATH}/#/login?type=zc&url=` + url;
  }
  if (t == "登录") {
    u = `${process.env.VUE_APP_ADMINPATH}/#/login?url=` + url;
  }
  window.location.href = u;
}
