<template>
  <div class="zc-rich-text-view">
    <div v-html="content || ''"></div>
  </div>
</template>
<script>
export default {
  name:"richTextViewer",
  props:{
    content:{
      type:String,
      default:""
    }
  },
  data(){
    return {

    }
  },


}
</script>
<style lang="less" >

</style>
