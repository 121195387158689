<template>
  <div class="dataElementDetails" v-loading="loading">
    <el-table
      :data="list"
      border
      ref="el_TableRef"
      highlight-current-row
      row-key="schemaId"
      style="width: 100%"
      :max-height="tableHeight"
    >
      <el-table-column type="index" width="80" label="序号" align="center">
        <template slot-scope="{ $index }">
          <span>{{ (currentPage - 1) * pageSize + $index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="item in fieldsList"
        :key="item.prop"
        :label="item.label"
        :width="item.width || '214px'"
      >
        <template slot-scope="{ row }">
          <span v-if="item.enum">{{ getEnumValue(item.enum, row[item.prop]) }}</span>
          <span v-if="!item.enum">{{ txtFN(item, row) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        :current-page="currentPage"
        :page-sizes.sync="pageSizes"
        :page-size.sync="pageSize"
        :total="total"
        class="p-bar"
        background
        layout="total,prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :disabled="loading"
      />
    </div>
  </div>
</template>
<script>
import TableMixin from "@/mixins/table";
import { getEnumValue } from "@/utils";
export default {
  name: "DataElementDetails",
  mixins: [TableMixin],
  props: {
    baseInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      config: {
        tableTop: 250
      },
      scrollToTopAfterGetList: false,
      listApiName: "dataPresentation4",
      fieldsList: [
        { label: "规范化字段名称", prop: "schemaName" },
        { label: "规范化字段类型", prop: "type" },
        {
          label: "是否主键",
          prop: "isPrimary",
          enum: [
            { label: "是", value: 1 },
            { label: "否", value: 0 }
          ]
        },
        { label: "规范化字段描述", prop: "schemaCn" },
        { label: "有效记录条数", prop: "count", width: "210px" }
      ],
      pageSize: 100,
      loading: false,
      search: {
        dataId: ""
      }
    };
  },

  mounted() {
    this.search.dataId = this.baseInfo.dataId;
    this.getList();
  },
  methods: {
    getEnumValue,
    txtFN(itm, row) {
      // row[itm.prop]
      // return row[itm.prop];
      return this.removeSchemaPrefix(row[itm.prop]);
    },
    removeSchemaPrefix(str) {
      // 确保 str 是一个字符串
      if (typeof str !== "string") {
        return str; // 或者抛出错误，取决于你的需求
      }

      // 检查字符串是否以 "schema://" 开头
      if (str.startsWith("schema://")) {
        // 移除 "schema://" 部分
        return str.substring("schema://".length);
      }

      // 如果不是，则直接返回原字符串
      return str;
    }
  }
};
</script>
<style lang="less" scoped>
.dataElementDetails {
}
</style>
