import { render, staticRenderFns } from "./seach.vue?vue&type=template&id=2fd09c52&scoped=true"
import script from "./seach.vue?vue&type=script&lang=js"
export * from "./seach.vue?vue&type=script&lang=js"
import style0 from "./seach.vue?vue&type=style&index=0&id=2fd09c52&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_prettier@3.3.3_vue-temp_5ppjrcubbg7262kwudt7jgpzsu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd09c52",
  null
  
)

export default component.exports