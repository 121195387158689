import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";
import { Toast } from "vant";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken, removeToken, toLogin } from "@/utils/auth"; // get token from cookie

NProgress.configure({ showSpinner: false }); // NProgress 配置不显示转圈

const authRouteList = ["/publicity", "/service", "/publicityDetails", "/flow", "/flow2"]; // 跳转黑名单
//拦截器
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.query.token) {
    sessionStorage.setItem(process.env.VUE_APP_TOKEN_KEY, to.query.token);
    return next({ path: "/" });
  }
  //判
  // 查看是否有token
  const hasToken = getToken();
  const hasUserInfo = store.getters.loginUserInfo.authorization;
  const userInfo = store.getters.loginUserInfo;
  //若有token

  if (hasToken) {
    //判断有没有userInfo没有就去请求
    if (!hasUserInfo) {
      try {
        await store.dispatch("getUserInfo");
        if (authRouteList.indexOf(to.path) !== -1) {
          return next(to.fullPath);
        } else {
          return next();
        }
      } catch (error) {
        return store.dispatch("logout", false);
      }
    }

    //判断是否是数据展示页
    if (to.path === "/service" && userInfo.isAuth !== 1) {
      Message({
        message: "请先完成企业认证",
        type: "warning"
      });
      NProgress.done();
      return next(from.fullPath);
    }
    if (authRouteList.includes(to.path)) {
      NProgress.done();
    }
    next();
  } else {
    console.log("没有token");
    if (authRouteList.indexOf(to.path) !== -1) {
      NProgress.done();
      toLogin("登录", to.path);
    } else {
      next();
    }
  }
});

/**
 * @description 路由跳转错误
 * */
router.onError(error => {
  NProgress.done();
  console.warn("路由错误", error.message);
});

/**
 * @description 路由跳转结束
 * */
router.afterEach((to, from) => {
  console.log("路由跳转结束", to, from);
  NProgress.done();
  window.scrollTo(0, 0);
});
