<template>
  <div class="l-column">
    <div class="banner" :style="`background: url(${globalConfig.aboutUseBanner}) no-repeat center center;`">
      <div class="bbox">
        <img class="bimg" :src="globalConfig.aboutUsLogo" />
        <div class="xian"></div>
        <div class="h3">关于我们</div>
      </div>
    </div>
    <div class="nav">
      <div class="w1200">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item :index="index" v-for="(itm, index) in navList" :key="index">{{ itm.title }}</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="cont" :style="idx != 3 ? 'background:#fff' : ''">
      <div></div>
      <div class="box1" id="box1" v-show="idx == 0">
        <RichTextViewer :content="contentMap['20']" />
      </div>

      <div class="box1 box2" v-show="idx == 1">
        <RichTextViewer :content="contentMap['30']" />
      </div>
      <div class="box1 box3" v-show="idx == 2">
        <RichTextViewer :content="contentMap['40']" />
      </div>

      <div class="box1 box4" v-show="idx == 3">
        <div class="a-h3">联系我们</div>
        <div class="b4top l-between">
          <div class="b4div l-between">
            <img class="b4img b" src="@/assets/img/a3.png" alt="" />
            <div class="b4rig">
              <div class="label">联系电话</div>
              <div class="val">{{ datas.contactNumber }}</div>
            </div>
          </div>
          <div class="b4div l-between">
            <img class="b4img b" src="@/assets/img/a4.png" alt="" />
            <div class="b4rig">
              <div class="label">联系邮箱</div>
              <div class="val">{{ datas.contactMail }}</div>
            </div>
          </div>
        </div>
        <div class="b4bom">
          <div class="container" id="container"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichTextViewer from "@/views/components/richTextViewer";
import { selectTitleApi } from "@/api/aboutus";
import { mapGetters } from "vuex";
export default {
  components: {
    RichTextViewer
  },
  data() {
    return {
      activeIndex: 0,
      idx: 0,
      navList: [{ title: "平台介绍" }, { title: "用户协议" }, { title: "法律声明" }, { title: "联系我们" }],
      contentMap: {
        20: "",
        30: "",
        40: ""
      },
      datas: {}
    };
  },
  computed: {
    ...mapGetters(["globalConfig"]),
    typeId() {
      let itms = sessionStorage.getItem("nodeValue");
      let str = "";
      if (itms) {
        itms = JSON.parse(itms);
        str = itms.id;
      }
      return str;
    }
  },
  watch: {},
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.mapInit();
    this.initContentMap();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    handleSelect(v) {
      this.idx = v;
    },
    initContentMap() {
      this.getContent(20);
      this.getContent(30);
      this.getContent(40);
    },
    async getContent(titleType) {
      try {
        let params = {
          titleType
        };
        let res = await selectTitleApi(params);
        let contentItem = res.data.list[0];
        this.contentMap[titleType] = contentItem.titleDetails;
      } catch (error) {
        console.log(error);
      }
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i]) ? (this.animateData[i] = true) : (this.animateData[i] = false);

        if (i == "animate14" && !isElementNotInViewport(this.$refs[i])) {
          setTimeout(() => {
            this.istwo = false;
          }, 700);
        }
        if (i == "animate14" && isElementNotInViewport(this.$refs[i])) {
          this.istwo = true;
        }
      }
    },
    mapInit() {
      let itms = sessionStorage.getItem("nodeValue");
      let longitudeAndLatitudeArr = [];
      let la = 116.506498;
      let at = 39.981857;
      if (itms) {
        itms = JSON.parse(itms);
        this.datas = itms;
      }
      if (itms && itms.longitudeAndLatitude) longitudeAndLatitudeArr = itms.longitudeAndLatitude.split(",");
      if (longitudeAndLatitudeArr.length > 1) {
        la = longitudeAndLatitudeArr[0] / 1;
        at = longitudeAndLatitudeArr[1] / 1;
      }

      var that = this;
      var map = new AMap.Map("container", {
        // mapStyle: "amap://styles/a0f0e495e9990fca3fa9fea6b753d29d",
        zoom: 15,
        center: [la, at], //this.workerList[0].monitor_value.split(','),
        // layers: [//使用多个图层
        //     new AMap.TileLayer.Satellite(),
        //     new AMap.TileLayer.RoadNet()
        // ],
        zooms: [3, 20] //设置地图级别范围
        // zoom: 13,
        // viewMode:'3D'//使用3D视图
      });
      // 创建一个 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(la, at), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: itms ? itms.addressName : ""
      });
      // 将创建的点标记添加到已有的地图实例：
      // 初始化鼠标工具类

      map.add(marker);

      // // 移除已创建的 marker
      // map.remove(marker);
    }
  }
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 270px;
  background-size: 1920px 270px;
  .bbox {
    width: 1200px;
    margin: 0 auto;
    padding-top: 80px;
  }
  .h3 {
    height: 55px;
    font-family: MicrosoftYaHeiLight;
    font-size: 36px;
    color: #ffffff;
    line-height: 64px;
    text-align: left;
    font-style: normal;
    margin-top: 5px;
  }
  .bimg {
    width: auto;
    height: 40px;
  }
}

.nav {
  width: 100%;
  height: 60px;
  background: #edeff3;
  /deep/.el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  /deep/.el-menu {
    background: #edeff3;
  }
  /deep/.el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid #2e3848;
    background: #edeff3 !important;
    font-weight: 900;
  }
}
.cont {
  min-height: 470px;
  padding-bottom: 100px;
  background: #f7f8fa;
  .box1 {
    padding-top: 59px;
    width: 1219px;
    margin: 0 auto;
    .a-h3 {
      width: 1219px;
      font-family: MicrosoftYaHei;
      font-size: 30px;
      color: #1d2538;
      line-height: 40px;
      text-align: left;
      font-style: normal;
    }
    .a-h4 {
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #626770;
      line-height: 34px;
      text-align: left;
      font-style: normal;
      font-weight: 900;
      margin: 30px 0 10px 0;
    }
    .a-txt {
      width: 1219px;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #626770;
      line-height: 34px;
      text-align: left;
      font-style: normal;
      margin-top: 22px;
    }
    .a-h2 {
      height: 29px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 22px;
      color: #626770;
      line-height: 29px;
      text-align: left;
      font-style: normal;
      margin: 42px 0 18px 0;
    }
    .a-p {
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #626770;
      line-height: 34px;
      text-align: left;
      font-style: normal;
    }
  }

  .box4 {
    height: 671px;
    .b4top {
      width: 1225px;
      margin: 0 auto;
      padding-top: 24px;
      .b4div {
        width: 599px;
        height: 131px;
        background: #ffffff;
        border-radius: 20px;
        .b4img {
          width: 55px;
          height: 55px;
          margin: 35px 0 0 35px;
        }
        .b4rig {
          padding: 33px 28px 0 0;
        }
        .label {
          height: 28px;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #6a6a6a;
          line-height: 28px;
          text-align: right;
          font-style: normal;
          text-transform: uppercase;
        }
        .val {
          height: 28px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 20px;
          color: #292929;
          line-height: 28px;
          text-align: right;
          // text-transform: uppercase;
          margin-top: 6px;
        }
      }
    }
    .b4bom {
      width: 1225px;
      height: 329px;
      background: #ffffff;
      border-radius: 20px;
      margin-top: 19px;
      padding: 15px 17px 22px 17px;
      .container {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}
</style>
<style>
.amap-logo {
  bottom: -100px;
  display: none;
}
.amap-copyright {
  bottom: -100px;
  display: none;
}
</style>
