<template>
    <div class="l-column">
      <div class="banner" :class="'banner'+datas.id">
        <div class="bbox">
            <img :src="datas.logo"  class="img">
            <div  class='h3'>{{datas.title}}</div>
            </div>
        </div>
         
        <div class="cont" >
            <div></div>
            <div class="box1" id="box1">
                <div class="l-h3" >解决方案</div>
                <div class="l-txt" style="min-height:16px;margin-bottom:20px;">{{datas.txt1}}</div>

                <div class="b2block b2block1"  >
                    <img class="b2bimg1" src="@/assets/img2024/service/d2.png">
                    <img class="b2bimg2" src="@/assets/img2024/service/a12.png">
                    <div class="btitle">显著效果</div>
                    <div class="btxt">{{ datas.txt2 }}</div>
                </div>
            </div>

           

            <div class="box3" id="box3">
                <div class="l-h3" style="padding-top:35px">解决问题</div>
                <div class="box3bom l-between">
                  <div class="block" v-for="itm in datas.list">
                    <img class="bimg" :src="itm.img">
                    <div class="title">{{ itm.title }}</div>
                    <div class="btxt">{{ itm.txt }}</div>
                  </div>
                </div>
            </div>
        </div>
        
       
      

    </div>
  </template>
  
  <script>
//   import { isElementNotInViewport } from "../../utils/index.js";
  export default {
    data() {
      return {
        activeIndex:0,
        navList:[
            {title:"二级节点建设能力"},
            {title:"对接国家顶级节点能力"},
            {title:"标识应用建设能力"},
        ],
        blockList:[
          {title:"方案设计",img:require('@/assets/img2024/service/s6.png')},
          {title:"应用研发",img:require('@/assets/img2024/service/s7.png')},
          {title:"数字化管理",img:require('@/assets/img2024/service/s8.png')},
          {title:"智能化管理",img:require('@/assets/img2024/service/s9.png')},
        
        ],
        datas:{},
        list:[
        {
                id:1,
                logo:require('@/assets/img2024/service/d1.png'),
                title:"水泥产品质量追溯",
                txt1:"结合海螺水泥现有云销系统，利用标识解析技术设计质量追溯系统，实现对水泥产品信息的查询和解析，使得海螺水泥能够准确地追踪水泥产品的生产流程、原材料来源、加工过程等信息，从而有效地监控产品质量，并在出现问题时迅速定位原因，采取相应的措施。",
                txt2:"海螺水泥对收集到的质量信息进行深入分析和挖掘，发现潜在的质量问题和改进空间，进一步优化生产流程，提高产品质量和客户满意度。为海螺水泥提供了强大的质量追溯和管理能力，提升了水泥产品的质量和市场竞争力。  ",
                list:[
                    {title:"体系不统一",img:require('@/assets/img2024/service/d13.png'),txt:"传统水泥质量追溯缺统一标准，企业差异大，需建统一标识与智能化追溯体系"},
                    {title:"追溯效率低",img:require('@/assets/img2024/service/d14.png'),txt:"在跨企业、跨地域的质量追溯过程中，信息无法有效对接和共享，导致追溯效率低下甚至失败"},
                    {title:"追溯体系不完整",img:require('@/assets/img2024/service/d15.png'),txt:"水泥生产过程中，各环节之间的信息流通不畅，无法形成完整的质量追溯体系"},
                ]
            },
            {
                id:2,
                logo:require('@/assets/img2024/service/d4.png'),
                title:"设备运行监管售后",
                txt1:"基于标识解析的设备售后服务系统，为企业销售设备分配唯一标识码，用户产品出现问题或者有其它需求扫描唯一标识发起售后服务请求，服务系统可以快速相应，并可集成短信、邮件等服务，通过标识码统一为用户提供设备售后服务。",
                txt2:"提高了设备的运行效率和管理水平，降低了故障率和维护成本，提升了企业的售后服务质量和客户满意度。同时，系统还为企业提供了全面的数据支持和决策依据，推动了企业的数字化转型和高质量发展。目前已经应用在广州健泽药业、广州志宁制药、山东红日康仁堂药业等近15家企业设备售后管理服务中。",
                list:[
                    {title:"故障处理不及时",img:require('@/assets/img2024/service/d16.png'),txt:"在设备出现故障时，可能由于信息传递不畅或响应机制不灵活，导致故障处理不及时。不仅会延长设备的停机时间，加重故障对生产的影响"},
                    {title:"设备数据分析不足",img:require('@/assets/img2024/service/d17.png'),txt:"由于缺乏足够的数据积累和分析能力，无法从设备运行和售后服务数据中提取有价值的信息支持工艺以及生产技术优化"},
                    {title:"售后沟通问题",img:require('@/assets/img2024/service/d18.png'),txt:"不同部门或团队之间可能存在协同和沟通障碍，导致设备运行监管和售后服务过程中的信息不畅"},
                ]
            },
            {
                id:3,
                logo:require('@/assets/img2024/service/d4.png'),
                title:"药品全流程生产管理",
                txt1:"基于标识解析的产品追溯系统，将企业生产的物料存储、材料加工生产、包装入库以及出口销售等环节关联起来，从数据采集节点的编码来进行数据链条打通，形成产品的全流程信息追溯。",
                txt2:"提高了中药产品的追溯效率和准确性，降低了质量问题的发生率，增强了消费者对中药产品的信任度和满意度。同时，系统还为锐嘉提供了全面的数据支持和决策依据，推动了企业的数字化转型和高质量发展。目前已经在产业链上下游包括华润三九、中国国药、同仁堂药业、大参林等在内的超过20家企业中使用。",
                list:[
                    {title:"药品难溯源",img:require('@/assets/img2024/service/d19.png'),txt:"药品安全事关重大，当发生安全问题时，药品生产数据难以追溯，难以找出安全问题所在"},
                    {title:"原材料质量控制不严格",img:require('@/assets/img2024/service/d20.png'),txt:"药品生产对原材料的质量要求极高，如果原材料质量控制不严格，可能导致药品质量不达标"},
                    {title:"质量管理体系不完善",img:require('@/assets/img2024/service/d21.png'),txt:"质量管理体系是确保药品质量的关键，传统质量监管体系不完善，会导致质量风险"},
                ]
            },
            {
                id:4,
                logo:require('@/assets/img2024/service/d7.png'),
                title:"燃气表供应链溯源",
                txt1:"追溯燃气表从原材料采购、生产制造、物流运输到最终销售的全过程，确保每个环节的信息都准确无误。同时实时监控燃气表的生产状态、质量信息以及物流动态，为企业的生产管理和物流调度提供实时数据支持。通过对燃气表供应链各环节数据的深度分析，预测潜在的风险和隐患，提前进行预警和干预，降低质量问题和安全事故的发生率。支持跨企业、跨地域的信息共享和协同作业，为燃气表供应链的各方提供便捷的信息交流和合作平台。",
                txt2:"中燃集团燃气表供应链溯源系统的应用，使得燃气表的质量安全得到了有效保障，中燃集团的供应链管理水平和运营效率得到了显著提升。同时，系统还为中燃集团的生产工艺改进提供了数据支撑，帮助中燃集团实现更加科学、合理的资源配置和生产安排，有效降低了燃气表故障率以及报修响应率。",
                list:[
                    {title:"市场集中度低",img:require('@/assets/img2024/service/d22.png'),txt:"中国智能燃气表行业的市场集中度较低，供应链中存在许多中小型企业，导致供应链管理和产品溯源十分复杂"},
                    {title:"远程操控问题",img:require('@/assets/img2024/service/d23.png'),txt:"用户对燃气表远程操控修改读数的担忧，反映出对供应链中技术信任度的缺失"},
                    {title:"政府监管需求",img:require('@/assets/img2024/service/d24.png'),txt:"政府基于对智能燃气表行业的监管政策，会对供应链的合规性和溯源管理提出更高要求"},
                ]
            },
            {
                id:5,
                logo:require('@/assets/img2024/service/d9.png'),
                title:"第三方施工监管",
                txt1:"通过为每一个第三方施工对象赋予唯一标识码，实现对这些对象的精确识别和定位。这些标识码不仅包含了施工对象的基本信息，还与其在供应链、施工过程中的位置、状态等关键信息相关联，能够实时追踪施工活动的进度和状态。通过定时更新施工现场的实时数据，使管理者能够直观地了解施工情况，包括设备的位置、运行状态、材料的消耗情况。不同部门、不同单位之间可以实时共享施工信息，提高沟通效率，减少信息孤岛。",
                txt2:"第三方施工系统能够根据实时数据对施工过程进行智能分析和预测，为决策提供有力支持。通过对施工人员、设备的标识和追踪，系统能够及时发现安全隐患，并采取相应措施进行防范和处理，减少安全事故发生。",
                list:[
                    {title:"监管体系存在漏洞",img:require('@/assets/img2024/service/d25.png'),txt:"部分第三方施工企业的监管体系存在漏洞或缺陷，导致在第三方施工过程中出现违规行为或安全隐患"},
                    {title:"施工效率低",img:require('@/assets/img2024/service/d26.png'),txt:"由于工程信息传递不及时或不准确，导致误解和冲突，影响施工效率和安全性"},
                    {title:"安全性低",img:require('@/assets/img2024/service/d27.png'),txt:"施工过程中涉及多个单位和个人，存在安全风险和管理难度。缺乏有效的安全管理和风险控制措施"},
                ]
            },
            {
                id:6,
                logo:require('@/assets/img2024/service/d9.png'),
                title:"物资全流程管理",
                txt1:"基于标识解析技术，国家管网利用物资全流程管理系统为所有物资分配唯一的标识码，这些标识码包含了物资的基本信息、来源、属性以及其在其他业务流程种相关业务信息。国家管网可以实时查询和追踪物资的状态和流向，从而实现对物资全流程的精细管理。",
                txt2:"有效解决管网设备物资存在的数据分散、标准不统一、上下游协同难等问题，打破制造、运行各环节的数据壁垒，统一数据标准，实现设备的全流程追踪管理，为上游厂家的质量追溯、管网内部的业务协同与精细化管理提供依据。",
                list:[
                    {title:"数据共享难",img:require('@/assets/img2024/service/d28.png'),txt:"物资在生产制造、建设、运营中的环节尚未完全打通，上下游协同难，同时物资交付方式不统一，数据录入、盘库费事费力"},
                    {title:"编码方式、标准不统一",img:require('@/assets/img2024/service/d29.png'),txt:"不同厂商产品编码不统一，难以与管网内部分类对应，工程设计、物资采购时匹配难"},
                    {title:"数据分散、未关联",img:require('@/assets/img2024/service/d30.png'),txt:"物资在全生命周期不同环节编码不统一，管网内部物资各阶段数据分散在不同业务系统中，且未关联，统计方式存在差异，难以管理"},
                ]
            },
            {
                id:7,
                logo:require('@/assets/img2024/service/d9.png'),
                title:"安全生产集中监管",
                txt1:"国家管网利用安全生产集中监管系统将作业相关业务信息作为标识对象注册赋码，在作业前、作业中、作业后全流程中发挥对施工任务、作业票、管网企业等相关业务对象进行统一关联，通过作业标识码快速了解作业动态，制定相关安全预案，实现安全生产集中监管。",
                txt2:"在安全生产集中监管方面，实现跨部门、跨地域的协同监管。通过数据共享和交换，不同部门和地区之间可以实时了解彼此的安全生产情况，共同应对可能出现的安全问题。打破传统安全信息孤岛，提升监管效率和效果。同时能够发现安全生产中的规律和趋势，为制定针对性的安全管理措施提供科学依据，帮助企业提升安全生产水平。",
                list:[
                    {title:"数据来源分散",img:require('@/assets/img2024/service/d31.png'),txt:"安全作业相关数据来源分散，分布于多个业务系统，导致多元数据未关联，信息未汇聚"},
                    {title:"安全生产定位难",img:require('@/assets/img2024/service/d32.png'),txt:"部分作业在全站区域流动，导致与视频监控设备“不关联”、“乱关联”情况突出"},
                    {title:"作业过程难追溯",img:require('@/assets/img2024/service/d33.png'),txt:"作业安全信息上报不及时，导致过程难以追溯"},
                ]
            },
        ]
      };
    },
    watch:{
      '$route.query.id':function (val) {
        this.list.map(res=>{
            if(res.id==val){
                this.datas = res
            }
        })
        
      }
    },
    mounted() {
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
      this.list.map(res=>{
            if(res.id==this.$route.query.id){
                this.datas = res
            }
        })
      
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    methods: {
     
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? (this.animateData[i] = true)
            : (this.animateData[i] = false);
  
            if(i=='animate14' && !isElementNotInViewport(this.$refs[i])){
                setTimeout(()=>{
                  this.istwo = false
  
                },700)
            }
            if(i=='animate14' && isElementNotInViewport(this.$refs[i])){
                  this.istwo = true
            }
  
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .banner {
      width:100%;
      height:337px;
      // background: url(../../assets/img2024/service/s1.png) no-repeat center center;
      // background-size:1920px 429px;
      .bbox{
        width:1200px; 
        margin:0 auto;
        padding-top:87px;

      }
      .h3{
        height: 57px;
        font-family: AlimamaShuHeiTi;
        font-size: 48px;
        color: #FFFFFF;
        line-height: 67px;
        text-align: left;
        font-style: normal;
      }  
  }
  .banner1{
    background: url(../../assets/img2024/service/d3.png) no-repeat center center;
    background-size:1920px 337px;
  }
  .banner2{
    background: url(../../assets/img2024/service/d5.png) no-repeat center center;
    background-size:1920px 340px;
  }
  .banner3{
    background: url(../../assets/img2024/service/d5.png) no-repeat center center;
    background-size:1920px 338px;
  }
  .banner4{
    background: url(../../assets/img2024/service/d8.png) no-repeat center center;
    background-size:1920px 339px;
  }
  .banner5{
    background: url(../../assets/img2024/service/d10.png) no-repeat center center;
    background-size:1920px 339px;
  }
  .banner6{
    background: url(../../assets/img2024/service/d11.png) no-repeat center center;
    background-size:1920px 338px;
  }
  .banner7{
    background: url(../../assets/img2024/service/d12.png) no-repeat center center;
    background-size:1920px 346px;
  }

  .cont{
    .box1{
        width:100%;
        height:475px;
        // background: url(../../assets/img2024/service/s2.png) no-repeat bottom center;
        // background-size:1920px 744px; 
        .img{
            margin:0 auto;
            display: block;
        }
    }
    .b2block{
                width: 1102px;
                height: 244px;
                box-shadow: 0px 0px 14px 10px rgba(22,37,101,0.04);
                border-radius: 8px;
                border: 1px solid;
                margin:0 auto;
                position: relative;
                margin-bottom:22px;
                z-index: 1;
                .b2bimg1{
                    width:71px;
                    height:71px;
                    margin:36px  0 0 38px;
                }
                .b2bimg2{
                    width:160px;
                    height:163px;
                    position: absolute;
                    top:23px;
                    right:25px;

                }
                .btitle{
                    margin:10px 0 6px 27px;
                    height: 33px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 24px;
                    color: #1D2538;
                    line-height: 33px;
                    text-align: left;
                    font-style: normal;
                }
                .btxt{
                    height: 56px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #6B7898;
                    line-height: 28px;
                    text-align: left;
                    font-style: normal;
                    padding:0 25px 0 27px;
                }
    }
    .b2block1{
        background: linear-gradient( 180deg, #F4F7FF 0%, #FFFFFF 100%);
        border-image: linear-gradient(180deg, rgba(204, 221, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }

    .box3{
        width:100%;
        height:465px;
        background: url(../../assets/img2024/service/a6.png) no-repeat bottom center;
        background-size:1920px 441px; 
        .txt{
          width:1078px;
        }
        .box3bom{
          width:1165px;
          margin:0 auto;
          margin-top:50px;
          .block{
            text-align: center;
            width:356px;
            .bimg{
              width:89px;
              height:89px;
            }
            .title{
              margin-top:17px;
              height: 35px;
              font-family: MicrosoftYaHei;
              font-size: 26px;
              color: #1D2538;
              line-height: 35px;
              text-align: center;
            }
            .btxt{
              width: 356px;
              font-family: MicrosoftYaHei;
              font-size: 16px;
              color: #6D727D;
              line-height: 28px;
              text-align: center;
              font-style: normal;
              margin-top:18px;
            }
          }
        }
    }
  }
  
  </style>
  