<template>
  <div
    class="homeDialog"
    v-loading="Loading"
    element-loading-text="上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.3)"
  >
    <div class="hdialog" :class="clas">
      <div class="bj"></div>
      <img class="imgr" src="../../assets/img/pd1.png" alt="" />
      <i class="el-icon-close b close" @click="closeBox"></i>

      <div class="l-column">
        <div class="box">
          <div class="top">
            <div class="title">{{ datas.dataName }}</div>
            <div class="txtbox l-start">
              <!-- <div class="txt l-nowraptxt">登记类型：{{ datas.dataTypeName }}</div> -->
              <div class="txt txt2 l-nowraptxt">登记主体：{{ datas.firmName }}</div>
            </div>
            <div class="txtbox l-start">
              <div class="txt l-nowraptxt">所属节点：{{ datas.nodeName }}</div>
              <div class="txt txt2 l-nowraptxt">数据起止时间：{{ datas.startDate }}～{{ datas.endDate }}</div>
            </div>
          </div>
          <div class="cen">
            <div class="label"><span class="l-font-red">*</span>异议内容</div>
            <el-input type="textarea" resize="none" :rows="6" placeholder="请输入你的异议内容" v-model="ruleForm.content">
            </el-input>
            <div class="label"><span class="l-font-red">*</span>相关材料</div>
            <!--  -->
            <!-- 
              :action="url"
              "
              :on-success="successFile"
              -->
            <el-upload
              ref="upload"
              :file-list="fileList"
              :on-remove="handleRemove"
              :http-request="handleUploadvideo"
              class="pupload"
              :limit="1"
              :on-progress="upladingdFn"
              :on-exceed="progressFn"
              :before-upload="handlePreview"
              drag
              multiple
            >
              <img class="imgup" v-if="!isnone" src="../../assets/img/pd2.png" alt="" />
              <img class="imgup" v-if="isnone" src="../../assets/img/pd3.png" alt="" />
              <div class="el-upload__text">选择文件或将文件拖放至此</div>
              <div class="el-upload__tip" slot="tip">支持png、jpg、jpeg、pdf等格式，文件不大于50MB</div>
            </el-upload>
          </div>
        </div>
        <div class="bom l-between">
          <div></div>
          <div class="brig l-start">
            <div class="hbtn b" @click="closeBox">关闭</div>
            <div class="hbtn b blue" @click="addFn">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addOrUpdateDisputeAPI, uploadAPI } from "@/api/common";
import { Loading } from "element-ui";
//    import { uploadAPI } from "@/api/user";

export default {
  props: ["dataId", "datas"],
  data() {
    return {
      clas: "hdialog2",
      isnone: false,
      textarea: "",
      fileList: [],
      ruleForm: {},
      isclick: true,
      isup: true,
      // url: process.env.VUE_APP_API_BASE_URL + "/adminFile/upload",
      Loading: false
    };
  },
  watch: {
    $route(v) {
      // this.routePath = v.path;
    }
  },
  mounted() {},
  methods: {
    handlePreview(file) {
      this.Loading = true;
      var size = Math.floor((file.size * 1) / 1024 / 1024);

      if (size > 50) {
        this.isup = false;
        this.$message.warning(`文件大小最大支持50MB`);
        this.Loading = false;
        return false;
      }
    },
    progressFn() {
      this.Loading = false;
      this.$message.error("只能上传一个文件，请删除后重新上传");
    },
    successFile(val) {
      if (val.code == 0) {
        this.Loading = false;
        this.$message.success("上传成功");
      } else {
        this.Loading = false;
        this.$message.error("上传失败");
        this.fileList = [];
        // this.$refs("upload").clearFiles();
      }
    },
    upladingdFn(progressEvent) {
      console.log(progressEvent);
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    },
    async handleUploadvideo(options) {
      if (!this.isup) {
        this.isup = true;
        this.$refs("upload").clearFiles();
        return;
      }

      try {
        let params = new FormData();
        params.append("file", options.file);
        let res = await uploadAPI(params);
        this.ruleForm.fileId = res.data.fileId;
        this.$message.success("上传成功");
        this.Loading = false;
        return res.data;
      } catch (error) {
        console.log(error);
        this.$message.error("上传失败");
        this.Loading = false;
        return Promise.reject(error);
      }
    },
    handleRemove(file, uploadFiles) {
      this.fileList = [];
      this.ruleForm.fileName = "";
      this.ruleForm.fileId = "";
      console.log(file, uploadFiles);
    },
    closeBox() {
      this.$emit("closeFn");
    },
    addFn() {
      if (!this.ruleForm.content || !this.ruleForm.fileId) {
        this.$message.error("请完善资料");
        return;
      }
      if (!this.isclick) return;
      this.isclick = false;
      let data = {
        dataId: this.dataId,
        ...this.ruleForm
      };
      addOrUpdateDisputeAPI(data)
        .then(res => {
          if (res.code == 0) {
            this.$message.success("提交成功");
            this.closeBox();
          } else {
            this.isclick = true;
          }
        })
        .catch(error => {
          this.isclick = true;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.homeDialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.55);
  z-index: 999;
  .hdialog {
    margin: 5% auto;
    background: url(../../assets/img/hd2.png) no-repeat bottom center;
    background-size: 100% 100%;
    position: relative;
    .imgr {
      position: absolute;
      right: 37px;
      top: 17px;
      z-index: 0;
    }
    .close {
      font-size: 20px;
      position: absolute;
      top: 26px;
      right: 21px;
      color: #aab9dd;
      z-index: 10;
    }
  }
  .hdialog {
    width: 712px;
    min-height: 626px;
    background: #ffffff;
    border-radius: 8px;
    padding: 0 30px;
    position: relative;
    .bj {
      width: 712px;
      height: 237px;
      background: linear-gradient(180deg, #e2f5fe 0%, rgba(246, 251, 254, 0) 100%);
      border-radius: 8px 8px 0px 0px;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
    }
    .box {
      flex: 1;
      position: relative;
      z-index: 1;
      .top {
        .title {
          height: 28px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 18px;
          color: #2f354d;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          margin-top: 41px;
          margin-bottom: 8px;
        }
        .txtbox {
          .txt {
            width: 150px;
            margin-right: 5px;
            height: 23px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #636988;
            line-height: 23px;
            text-align: left;
            font-style: normal;
          }
          .txt2 {
            flex: 1;
            padding-right: 5px;
          }
        }
      }
      .cen {
        .label {
          height: 19px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #3c465e;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          margin: 20px 0 6px 0;
        }
        .pupload {
          min-height: 190px;
        }
        .el-upload__text {
          height: 16px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #9da8ba;
          line-height: 16px;
          font-style: normal;
        }
        .el-upload__tip {
          height: 16px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #bec6d1;
          line-height: 16px;
          text-align: left;
          font-style: normal;
        }
        /deep/.el-upload-dragger {
          width: 652px;
          height: 125px;
        }
        .imgup {
          margin-top: 36px;
        }
      }
    }

    .bom {
      border-top: 1px solid #e7e7e7;
      width: 100%;
      height: 90px;
      padding-right: 38px;
      .brig {
        margin-top: 22px;
      }
    }
  }

  .hbtn {
    width: 131px;
    height: 44px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dce4f5;
    font-family:
      PingFangSC,
      PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #c6d1ea;
    line-height: 44px;
    text-align: center;
    font-style: normal;
    margin-left: 8px;
  }
  .blue {
    background: #2165fc;
    color: #ffffff;
    border: 1px solid #2165fc;
  }
}
</style>
