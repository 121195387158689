import request from './request'
// 获取用户信息
export function getUserInfoApi() {
  return request({
    url: '/adminUser/queryLoginUser',
    method: 'post'
  })
}
export function logoutAPI() {
  return request({
    url: '/logout',
    method: 'post'
  })
}
// 上传
export function uploadAPI(data) {
  return request({
    url: '/adminFile/upload',
    method: 'post',
    data
  })
}

