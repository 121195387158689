<template>
  <div class="footer-wrap">
    <!-- <div class="footer l-between">
      <div class="foot-left l-start" >
          <div class="fbox" v-for="itm in fList">
            <div class="h2">{{ itm.title }}</div>
            <div class="ul b" v-for="item in itm.list " @click="goFn(item)">{{ item.title }}</div>
          </div>

      </div>
      <div class="foot-left" >
        <div class="qrcode" >
          <img src="../../assets/img2024/code.png" alt="" />
        </div>

      </div>

    </div> -->
    <div class="bom">
      <div class="bombox l-start">
        <div class="txt txt1">{{ globalConfig.copyRightText || '' }}</div>
        <!-- <img src="../../assets/img/footer_2.png" alt="" /> -->
        <div class="txt txt2">{{ globalConfig.icpText || '' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { selectReceptionTitle } from "@/api/common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      routePath: "",
      isnews: [],
      fList: [
        {
          title: "首页",
          list: [{ title: "首页", url: "/home" }]
        },
        {
          title: "服务能力",
          list: [
            { title: "二级节点建设能力", url: "/service" },
            { title: "对接国家顶级节点能力", url: "/service" },
            { title: "标识应用建设能力", url: "/service" }
          ]
        },
        {
          title: "标识解析体系",
          list: [
            { title: "标识", url: "/analyze" },
            { title: "MA编码", url: "/analyze" },
            { title: "MA编码体系", url: "/analyze" },
            { title: "标识解析价值", url: "/analyze" }
          ]
        },
        {
          title: "应用案例",
          list: [{ title: "应用案例", url: "/applyCase" }]
        }
      ]
    };
  },
  watch: {
    $route(v) {
      this.routePath = v.path;
    }
  },
  computed: {
    ...mapGetters(["globalConfig"]),
    // icp() {
    //   let itms = sessionStorage.getItem("nodeValue");
    //   let str = "";
    //   if (itms) {
    //     itms = JSON.parse(itms);
    //     str = itms.id == 2 ? "苏ICP备2024091685号-1" : "京ICP备19044799号-3";
    //   }
    //   return str;
    // },
    // copyright() {
    //   let itms = sessionStorage.getItem("nodeValue");
    //   let str = "";
    //   if (itms) {
    //     itms = JSON.parse(itms);
    //     str =
    //       itms.id == 2
    //         ? "Copyright ◎ 2024 长三角一体化示范区(江苏)慧聚数据服务有限公司"
    //         : "Copyright ◎ 2022-2023 中国工业互联网研究院 版权所有";
    //   }
    //   return str;
    // }
  },
  mounted() {
    this.routePath = this.$route.path;
    // this.getList(6)
    // this.getList(7)
  },
  methods: {
    goFn(item) {
      document.title = item.title;
      this.$router.push({
        path: item.url,
        query: { title: item.title }
      });
    },
    getList(id) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        titleType: id
      };
      // selectReceptionTitle(data)
      //   .then(res => {
      //     if (res.code == 0) {
      //       for(let i=0;i<res.data.records.length;i++){
      //         this.isnews.push(res.data.records[i])
      //       }

      //     } else {
      //     }
      //   })
      //   .catch(error => {});
    },
    gohFn() {
      window.open("https://beian.miit.gov.cn/");
    },
    gohFn2() {
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402011394");
    },
    navHandle(item) {
      document.title = item.title;
      this.$router.push({
        path: item.url
      });
    }
  }
};
</script>

<style lang="less" scoped>
.footer-wrap {
  background: rgba(3, 6, 32, 0.89);
  // height: 322px;
  position: relative;
}
.footer {
  width: 1180px;
  margin: 0 auto;
  display: flex;
}

.foot-left {
  .qrcode {
    width: 155px;
    height: 156px;
    background: #fff;
    color: #243159;
    font-size: 12px;
    text-align: center;
    margin-top: 64px;
    img {
      display: block;
      width: 155px;
      height: 156px;
      margin: 0 auto 1px;
    }
  }
  .report {
    color: rgba(255, 255, 255, 0.6);
    div {
      margin-bottom: 8px;
    }
  }
  .report-icon {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
  .fbox {
    width: 236px;
    padding-top: 53px;
    .h2 {
      height: 21px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      margin-bottom: 35px;
    }
    .ul {
      height: 19px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);

      line-height: 19px;
      text-align: left;
      font-style: normal;
      margin-bottom: 18px;
    }
  }
}

.bom {
  height: 54px;
  background: #5f5f5f;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  line-height: 54px;
  .bombox {
    width: 530px;
    margin: 0 auto;
    img {
      width: 16px;
      height: 16px;
      position: relative;
      top: 10px;
    }
    .txt1 {
      margin: 0 10px 0 0px;
    }

    .txt2 {
    }
    .txt {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.64);
    }
  }
}
</style>
