<template>
    <div class="homeDialog" >
            <div class="hdialog " :class="clas">
                <img class="imgl" src="../../assets/img/hd3.png" alt="" />
                <img class="imgr" src="../../assets/img/hd4.png" alt="" />
                <i class="el-icon-close b close" @click="closeBox"></i>
                <div class="l-column" v-if="clas=='hdialog1'">
                    <div class="box l-start" >
                    <img class="img" src="../../assets/img/hd1.png" alt="" />
                    <div class="rig">
                        <div class="title">您好！欢迎进入工业数据资产登记平台</div>
                        <div class="txt l-start">
                            为了更好的为您服务，请先进行
                            <div class="a b" >企业信息认证!</div>
                        </div>
                    </div>
                </div>
                <div class="bom l-between">
                    <div></div>
                    <div class="brig l-start">
                        <div class="hbtn b" @click="closeBox">关闭</div>
                        <div class="hbtn b blue">立即前往认证 </div>
                    </div>
                </div>
                </div>
                <div class="l-column" v-if="clas=='hdialog2'">
                    <img class="img" src="../../assets/img/hd6.png" alt="" />
                    <div class="title">认证审核中</div>
                    <div class="txt">预计5个工作日内完成审核，请耐心等待！</div>
                    <div class="bom l-start">
                        <div class="hbtn b blue" @click="closeBox">关闭</div>
                    </div>
                </div>
                <div class="l-column" v-if="clas=='hdialog3'">
                    <img class="img" src="../../assets/img/hd5.png" alt="" />
                    <div class="title">认证信息被退回</div>
                    <div class="txt">请查看退回原因，并重新提交企业信息认证</div>
                    <div class="bom l-start">
                        <div class="hbtn b" @click="closeBox">关闭</div>
                        <div class="hbtn b blue">去调整企业信息</div>
                    </div>

                </div>
            </div>

    </div>
  </template>
  
  <script>
  // import { selectReceptionTitle } from "@/api/common";
  
  export default {
    data() {
      return {
        clas:"hdialog2"
       
      };
    },
    watch: {
      $route(v) {
        // this.routePath = v.path;
      },
    },
    mounted() {
     
    },
    methods: {
        rowFn(itm){
            this.datas= itm
            this.isDetails=true
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        closeBox(){
            this.$emit('closeFn')
        },
        getList(id) {
            let data = {
                pageNo:1,
                pageSize:10,
                titleType:id
                };
            // selectReceptionTitle(data)
            //   .then(res => {
            //     if (res.code == 0) {
            //       for(let i=0;i<res.data.records.length;i++){
            //         this.isnews.push(res.data.records[i])
            //       }
                
            //     } else {
            //     }
            //   })
            //   .catch(error => {});
        },
        
        navHandle(item) {
            document.title = item.title;
            this.$router.push({
            path: item.url,
            });
        },
        },
    };
  </script>
  
  <style lang="less" scoped>
    .homeDialog{
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background:rgba(0,0,0,.55);
        z-index: 999;
        .hdialog{
            margin:10% auto;
            background: url(../../assets/img/hd2.png) no-repeat bottom center;
            background-size:100% 100%; 
            position: relative;
            .imgl{
                position: absolute;
                left:0;
                top:0;
                z-index: 0;
            }
            .imgr{
                position: absolute;
                right:0;
                top:0;
                z-index: 0;
            }
            .close{
                font-size:20px;
                position: absolute;
                top:26px;
                right:21px;
                color:#AAB9DD;
            }
            
        }
        .hdialog1{
            width:869px;
            height:393px;
            padding:94px 0 0 72px;
            .box{
                flex:1;
            }
            .img{
                width:90px;
                height:90px;
                margin-right: 15px;
            }
            .title{
                height: 37px;
                font-family: MicrosoftYaHei;
                font-size: 28px;
                color: #222A3F;
                line-height: 37px;
                text-align: right;
                font-style: normal;
                margin-top:8px;
            }
            .txt{
                height: 25px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #687186;
                line-height: 25px;
                text-align: left;
                font-style: normal;
                margin-top:10px;
                .a{
                    color:#2165FC;
                    font-size: 18px;
                    text-decoration: underline;
                }
            }
            .bom{
                border-top: 1px solid #E7E7E7;
                width:100%;
                height:90px;
                padding-right: 38px;
                .brig{
                    margin-top:22px;
                }
            }
        }
        .hdialog3,.hdialog2{
            text-align: center;
            width:676px;
            height:418px;
            .img{
                width:173px;
                height:188px;
                margin:0 auto;
                margin-top:34px;
            }
            .title{
                height: 26px;
                font-family: MicrosoftYaHei;
                font-size: 20px;
                color: #313E5D;
                line-height: 26px;
                text-align: center;
                font-style: normal;
                margin-top:-20px;
            }
            .txt{
                height: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #687186;
                line-height: 20px;
                text-align: center;
                font-style: normal;
                margin-top:18px;
            }
            .bom{
                margin:0 auto;
                margin-top:37px;

            }

        }
        .hbtn{
            width: 131px;
            height: 44px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #DCE4F5;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #C6D1EA;
            line-height:44px;
            text-align: center;
            font-style: normal;
            margin-left:8px;
        }
        .blue{
            background: #2165FC;
            color: #FFFFFF;
            border: 1px solid #2165FC;
        }
    }

    
  </style>
  