<template>
    <div class="l-column">
      <div class="banner">
        <div class="bbox">
            <div  class='h3'>应用案例</div>
            <div class="xian"></div>
            <div class="txt">标识应用建设帮助企业实现数据跨平台融合，发挥数据新价值，普遍应用于供应链协同、质量溯源、全生命周期管理、防伪防窜货等场景，一码溯源、一码通览，助力企业数智化转型。</div>
            </div>
        </div>
        <div class="cont" >
            <div class="box l-between l-wrap">
                <div class="block l-column" v-for="itm in  blockList" >
                      <div class="imgbox"><img :src="itm.img" class="img"></div>
                      <div class="bbom">
                        <img :src="itm.logo" class="logo">
                        <div class="title">{{ itm.title }}</div>
                        <div class="txt">{{ itm.txt }}</div>
                        <div class="btns l-start b" @click="goFn(itm)">
                          <div class="bt">查看详情</div>
                          <img class="bimg" src="@/assets/img2024/service/c6.png">
                        </div>
                      </div>
                </div>
            </div>
        </div>
        
       
      

    </div>
  </template>
  
  <script>
//   import { isElementNotInViewport } from "../../utils/index.js";
  export default {
    data() {
      return {
        blockList:[
        {
            id:1,
            title:"水泥产品质量追溯",
            logo:require('@/assets/img2024/service/c2.png'),
            img:require('@/assets/img2024/service/c7.png'),
            txt:"利用标识解析技术，实现对水泥产品从生产源头到消费市场的精细管理以及生产环节质量信息的全程记录，使水泥产品信息更加透明。"
          },
          {
            id:2,
            title:"设备运行监管售后",
            logo:require('@/assets/img2024/service/c5.png'),
            img:require('@/assets/img2024/service/c13.png'),
            txt:"通过设备标识码，实现设备信息、运行状态以及维保记录的精准查询。实时采集设备运行数据，进行深度分析处理，为企业的设备管理和售后服务提供了全面、准确的数据支撑。"
          },
          {
            id:3,
            title:"药品全流程生产管理",
            logo:require('@/assets/img2024/service/c5.png'),
            img:require('@/assets/img2024/service/c8.png'),
            txt:"利用标识解析技术，实现对中药产品从原材料采购、生产加工、仓储物流到终端销售的全流程业务管理，为中药产品生产的质量管理和风险控制提供数据支撑。"
          },
          {
            id:4,
            title:"燃气表供应链溯源",
            logo:require('@/assets/img2024/service/c3.png'),
            img:require('@/assets/img2024/service/c9.png'),
            txt:"利用标识解析技术，对燃气表进行从物件采购、生产制造、物流运输到最终安装的全生命周期管理，通过动态采集燃气表内部数据结合供应链生产信息，实现燃气表供应链溯源。"
          },
          {
            id:5,
            title:"第三方施工监管",
            logo:require('@/assets/img2024/service/c4.png'),
            img:require('@/assets/img2024/service/c10.png'),
            txt:"利用标识解析技术，实现对第三方施工的全程监管，现场施工人员通过标识及时同步施工进展和风险信息，进一步降低第三方施工风险。"
          },
          {
            id:6,
            title:"物资全流程管理",
            logo:require('@/assets/img2024/service/c4.png'),
            img:require('@/assets/img2024/service/c11.png'),
            txt:"利用工程物资唯一标识码全面贯通物资全流程业务信息，对外打通供应商体系，对内打通不同业务系统，实现数据深度融合，发挥物资数据新价值。"
          },
          {
            id:7,
            title:"安全生产集中监管",
            logo:require('@/assets/img2024/service/c4.png'),
            img:require('@/assets/img2024/service/c12.png'),
            txt:"通过为设备、工艺环节、操作人员分配标识码，标识码关联各业务系统的安全状态、历史记录等关键数据，监管部门可以通过标识全面掌握安全生产状况。"
          },
        
        ]
      };
    },
    watch:{
     
    },
    mounted() {
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
     
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    methods: {
      goFn(itm){
        this.$router.push({
          path: '/applyCaseDetails',
          query:{id:itm.id}
        });
      },
      
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? (this.animateData[i] = true)
            : (this.animateData[i] = false);
  
            if(i=='animate14' && !isElementNotInViewport(this.$refs[i])){
                setTimeout(()=>{
                  this.istwo = false
  
                },700)
            }
            if(i=='animate14' && isElementNotInViewport(this.$refs[i])){
                  this.istwo = true
            }
  
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .banner {
      width:100%;
      height:429px;
      background: url(../../assets/img2024/service/c1.png) no-repeat center center;
      background-size:1920px 429px;
      .bbox{
        width:1200px; 
        margin:0 auto;
        padding-top:103px;

      }
      .h3{
            height: 57px;
            font-family: AlimamaShuHeiTi;
            font-size: 48px;
            color: #1B2A4D;
            line-height: 67px;
            text-align: left;
            font-style: normal;
      }
      .xian{
        width: 85px;
        height: 7px;
        background: linear-gradient( 90deg, #2165FC 0%, #49C3FF 51%, #F870EE 100%);
        border-radius: 0px 3px 0px 3px;
        margin:31px 0 24px 0;
      }
      .txt{
        width: 606px;
        height: 60px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #868C99;
        line-height: 30px;
        text-align: left;
        font-style: normal;
      }
  }
  .cont{
    width: 100%;
    background: #F3F7FB;
    .box{
      width:1227px;
      margin:0 auto;
      padding-top:61px;
      .imgbox{
        width: 385px;
        height: 246px;
        overflow: hidden;
        border-radius: 10px;
      }
      .block{
        width: 385px;
        
        &:hover{
          .img{
              transform: scale(1.2);
          }
        }
        .img{
          width: 385px;
          height: 246px;
          border-radius: 10px;
          box-shadow: 0px 0px 16px 8px rgba(0,0,0,0.02);
          transition: transform 0.3s ease-in-out;
        }
        .bbom{
          padding:0 20px;
          width: 385px;
          height: 316px;
          // background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 100%);
          background-color: rgba(255, 255, 255, 0.5);
          box-shadow: 0px 0px 16px 8px rgba(0,0,0,0.02);
          border-radius: 10px;
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
          position: relative;
          top:-30px;
          z-index: 1;
          .logo{
            height:52px;
            margin:22px 0 8px 0;
          }
          .title{
            height: 26px;
            font-family: MicrosoftYaHei;
            font-size: 20px;
            color: #1D2538;
            line-height: 26px;
            text-align: left;
            font-style: normal;
          }
          .txt{
            width: 344px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #555E73;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            margin:6px 0 37px 0 ;
          }
          .btns{
            width: 116px;
            height: 43px;
            border-radius: 23px;
            border: 1px solid rgba(191,209,245,0.67);
            text-align: center;
            line-height: 43px;
            position: absolute;
            bottom:38px;
            left:22px;
              .bt{
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #2579FF;
                font-style: normal;
                margin: 0 8px 0 20px;
              }
              .bimg{
                width: 14px;
                height: 13px;
                position: relative;
                top:16px;
              }
          }
        }
      }
    }
  }
  
  </style>
  