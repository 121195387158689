import request from './request'
// 查询数据展示列表
export function queryDataShowList(data) {
  return request({
    url: '/dataElement/queryDataShow',
    data,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 查询数据展示详情
export function getDataDetail(id) {
  return request({
    url: `/dataElement/queryById/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 查询数据要素详情
export function getDataElementDetails(data) {
  return request({
    url: `/dataTableSchema/getSchemaByDataId`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 查询数据要素质量报告__总表
export function getDataElementQualityReporting__SummaryTable(data) {
  return request({
    url: `/dataTableSchema/listAssetsByDataId`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 查询数据要素质量报告__详表
export function getDataElementQualityReporting__FullList(data) {
  return request({
    url: `/dataTableSchema/listTableSchemaByDataId`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//节点配置信息（logo footer-tips ...）
export function getNodeConfig(){
  return request({
    url: `/nodes/getCurrentNodeConfig`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


