/**
 * 判断某个原生DOM元素是否不在屏幕可见区内
 * @param {*} el 原生DOM元素
 */
import {
  Message,
} from 'element-ui'
import { debounce } from 'lodash'
const isElementNotInViewport = function (el) {
  let rect = null
  try {
    rect = el.getBoundingClientRect();
  } catch (e) {
    rect = el[0].getBoundingClientRect();
  }

  return (
    rect.top >= (window.innerHeight || document.documentElement.clientHeight) ||
    rect.bottom <= 0
  );
};
/**
* 函数节流
*/
const throttle = function (fn, delay) {
  var lastTime;
  var timer;
  var delay = delay || 200;
  return function () {
    var args = arguments;
    // 记录当前函数触发的时间
    var nowTime = Date.now();
    if (lastTime && nowTime - lastTime < delay) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        // 记录上一次函数触发的时间
        lastTime = nowTime;
        // 修正this指向问题
        fn.apply(this, args);
      }, delay);
    } else {
      lastTime = nowTime;
      fn.apply(this, args);
    }
  }
}
export { isElementNotInViewport, throttle };
//window打开文件
export function openFile(fileId,target="_blank"){
  if(!fileId) return
  let url =  process.env.VUE_APP_API_BASE_URL + '/adminFile/down/' + fileId
  window.open(url,target)
}

//点击复制
export function copyText(content){
  if(navigator.clipboard){
    navigator.clipboard.writeText(content).then(()=>{
      Message.success('复制成功')
    }).catch(()=>{})
  }else{
    copyTextNonSafetyEnvironment(content)
  }
}


export function copyTextNonSafetyEnvironment(text) {
  // 创建一个临时的 textarea 元素
  var tempTextarea = document.createElement("textarea");

  // 设置 textarea 的内容为要复制的文本
  tempTextarea.value = text;

  // 将 textarea 添加到文档中
  document.body.appendChild(tempTextarea);

  // 选中 textarea 的内容
  tempTextarea.select();

  // 执行复制命令
  document.execCommand("copy");

  // 移除临时的 textarea 元素
  document.body.removeChild(tempTextarea);
  Message.success('复制成功')
}
export const errorMessage = debounce((message, type = 'error') => {
  Message({
    message: message,
    duration: 1500,
    type: type
  })
},800)

//千分位计数法
export function formatNumberThousands(num) {
  //判断num是数字还是字符串
  if (typeof num !== 'number' && typeof num !== 'string' && (typeof num === 'string' && !/^\d+$/.test(num)) ) {
    console.error('num参数必须是数字或数字字符串')
    return num
  }
  num = parseInt(num) + ''
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function getEnumValue(enumArr,value,fieldName = {label:'label',value:'value'}){

  let item = enumArr.find(item=>item[fieldName.value] == value)
  if(item){
    return item[fieldName.label]
  }else{
    return '--'
  }
}
