import axios from "axios";
import { Message } from "element-ui";
import qs from "qs";
import { debounce } from "lodash";
import router from "@/router";
import { getToken, removeToken, removeUserInfo } from "@/utils/auth";
import store from "@/store";
import { errorMessage } from "@/utils/index";

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers["Admin-Token"] = getToken() || "";
    }

    const flag = config.headers["Content-Type"] && config.headers["Content-Type"].indexOf("application/json") !== -1;
    if (!flag) {
      const mult = config.headers["Content-Type"] && config.headers["Content-Type"].indexOf("multipart/form-data") !== -1;
      if (mult) {
        config.data = config.data;
      } else {
        config.data = qs.stringify(config.data);
      }
    } else {
      if (config.data === undefined || config.data === null) {
        // 不传参的情况下 json类型的提交数据，校准为 空对象
        config.data = {};
      }
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    if (response.status === 200 && response.config.responseType === "blob") {
      // 文件类型特殊处理
      if (
        response.headers["content-disposition"] ||
        (response.headers["content-type"] && response.headers["content-type"].indexOf("application/pdf") != -1)
      ) {
        return response;
      } else if (response.data) {
        const resultBlob = new Blob([response.data], { type: "application/json" });
        const fr = new FileReader();
        fr.onload = function () {
          const result = JSON.parse(this.result);
          // 附件下载反馈的302 忽略
          if (result.msg && result.code !== 302) {
            errorMessage(result.msg, result.code == 1 ? "success" : "error");
          }
        };
        fr.readAsText(resultBlob);
      }
    } else if (res.code !== 0) {
      // 302	登录已失效
      if (res.code === 1001 || res.code === 302) {
        //删除token
        removeToken();
        //删除本地用户信息
        store.commit("RESET_USERINFO");
        router.push("/");
      }
      errorMessage(res.msg || "网络错误");
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  error => {
    if (error.response) {
      const response = error.response;
      if (response.status == 500) {
        errorMessage("网络错误，请检查您的网络");
      } else if (response.data && response.data.msg) {
        errorMessage(response.data.msg);
      }
    }
    return Promise.reject(error);
  }
);

export default service;
