let env = 'prd'

let href = window.location.href
let http='http'
let httporg = window.location.origin
if ( href.indexOf('localhost') > -1 || href.indexOf('192.168.67') > -1) {
  env = 'dev'
} else if (href.indexOf('192.168.6.228') > -1) {
  env = 'test'
} else {
  env = 'prd'
}
if (href.indexOf("https") !== -1) {
  http = "https";
} else {
  http = "http";
}
// let types ='国家'
let types ='城市'

function getParams () {
  //return {
    //appServiceUrl:
    //'http://t200conference-manager.zhengtoon.com'
 // }
    if (env === 'dev') {
      return {
        // /appServiceUrl: 'http://df.minganmed.com/'
        appServiceUrl: '/api',                         //接口地址
        appServiceUrldown: http+'://192.168.6.230/'+process.env.VUE_APP_API_BASE_URL,
        appServiceUrlPro:http+'://192.168.6.230/',
        appType:types,
      }
    } else if (env === 'test') {
      return {
        appServiceUrl: httporg + process.env.VUE_APP_API_BASE_URL,
        appServiceUrldown:  httporg+'/',
        appServiceUrlPro:httporg+'/',
        appType:types

      }
    } else {
      return {
        appServiceUrl: httporg + process.env.VUE_APP_API_BASE_URL,
        appServiceUrldown:  httporg+'/',
        appServiceUrlPro:httporg+'/',
        appType:types
      }
    }
}
//国家 dataRegistrationnation   城市:dataRegistrationcity
export default getParams()


