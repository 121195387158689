<template>
  <el-card class="box-card" shadow="hover">
    <div class="top l-start">
      <img class="logo" :src="getLogo()" alt="" />
      <div class="rightInfo">
        <div class="name" :class="{ oneLineWord: scene === 1 }">{{ detail.dataName || '' }}</div>
        <!-- <div class="scoring">
          <el-rate
            v-model="detail.starLevel"
            disabled
            show-score
            text-color="#ff9900"
            score-template="{value}">
          </el-rate>
        </div> -->
        <div class="copyRightOwner" v-if="scene === 1">
          <div class="company" :class="{ oneLineWord: scene === 1 }">{{ detail.firmName || '' }}</div>
          <!-- <div class="gap">|</div> -->
          <div class="node" :class="{ oneLineWord: scene === 1 }">{{ detail.nodeName || '' }}</div>
        </div>
        <div v-if="scene === 2" style="margin-top: 15px">
          {{ detail.code || '' }}
          <!-- <i class="el-icon-document-copy"></i> -->
        </div>
      </div>
    </div>
    <div class="disc">
      {{ detail.description || '' }}
    </div>
  </el-card>
</template>
<script>
import { copyText } from "@/utils";
let defaultLogo = require("@/assets/img/dataAvatar.png");
export default {
  name: "DataPresentationBlock",
  props: {
    detail: {
      type: Object,
      default() {
        return {};
      }
    },
    scene: {
      //1列表页2详情页
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      rate: 2.1
    };
  },
  methods: {
    getLogo() {
      if (this.detail.logo) {
        return process.env.VUE_APP_API_BASE_URL + "/adminFile/down/" + this.detail.logo;
      }
      return defaultLogo;
    },
    copyText
  }
};
</script>
<style lang="less" scoped>
//创建一个名为test的less混入
.oneLineWord {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box-card {
  cursor: pointer;
}
.top {
  .logo {
    width: 65px;
    height: 65px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 10px;
  }
  .rightInfo {
    font-family: MicrosoftYaHei;
    width: calc(100% - 75px);
    .name {
      font-size: 16px;
      color: #2e3848;
      line-height: 21px;
    }
    .scoring {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .copyRightOwner {
      font-size: 12px;
      color: #979ea7;
      margin-top: 8px;
      line-height: 16px;
      align-items: center;
      .node {
        margin-top: 5px;
      }
      .gap {
        margin: 0 5px;
      }
    }
  }
}
.disc {
  margin-top: 20px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #797f89;
  text-align: left;
  font-style: normal;
  //只能显示两行，多出省略号
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 控制显示的行数 */
  height: 3.2em; /* 控制显示的高度，需根据实际情况调整 */
  line-height: 1.6em; /* 行高的两倍，确保两行文本的显示 */
}
</style>
