<template>
  <div class='privacy'>
<div class="article">
		<h1>隐私政策</h1>
		<p>发布日期：2021年2月7日 </p>
		<p style='position: relative;top:-1em'>生效日期：2021年2月7日  </p>
		<p></p>
		<p>广目安全极度重视您的用户隐私。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您的理解。

			您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。</p>
		<p><strong>本《隐私政策》将帮助您了解以下内容：</strong></p>
		<h2>目录</h2>
		<p>
			第一章 我们可能收集的您的信息
			<br>
			第二章 我们可能如何使用收集的信息
			<br>
			第三章 我们可能分享的您的信息
			<br>
			第四章 您可能分享的信息
			<br>
			第五章 我们将如何保护您的信息
			<br>
			第六章 未成年人信息的保护
			<br>
			第七章 本隐私政策的适用范围
			<br>
			第八章 本隐私政策的变更
			<br>
			第九章 投诉及建议
		</p>
		<h2>第一章 我们可能收集的您的信息</h2>
		<p><strong>1. 我们可能获取的您的信息</strong></p>
		<p>1.1 日志信息，指您使用我们的服务时，系统可能通过cookies或其他方式自动采集的技术信息，包括：</p>
		<ul>
			<li>
				1.1.1 设备或软件信息，例如您的移动设备或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；
			</li>
			<li>1.1.2 在使用我们服务时搜索或浏览的信息，例如您在使用我们服务时浏览或要求提供的其他信息和内容详情；</li>
			<li>1.1.3 您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。</li>
		</ul>
		<p>1.2 位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：</p>
		<ul>
			<li>1.2.1您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息；</li>
			<li>1.2.2您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息；</li>
			<li>1.2.3您可以通过关闭定位功能，停止对您的地理位置信息的收集。</li>
		</ul>
		<p><strong>2. 您提供的信息</strong></p>
		<p>2.1您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息；</p>
		<p>2.2其他方分享的您的信息；</p>
		<p>2.3其他方使用我们的服务时所提供有关您的共享信息。</p>
		<h2>第二章 我们可能如何使用收集的信息</h2>
		<p><strong>1. 我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</strong></p>
		<p>1.1向您提供服务；</p>
		<p>1.2在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，1.3确保我们向您提供的产品和服务的安全性；</p>
		<p>1.3帮助我们设计新服务，改善我们现有服务；</p>
		<p>1.4使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；</p>
		<p>1.5向您提供与您更加相关的广告以替代普遍投放的广告；</p>
		<p>1.6评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；</p>
		<p>1.7软件认证或管理软件升级；</p>
		<p>1.8让您参与有关我们产品和服务的调查。</p>
		<p>1.9为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
		</p>
		<h2>第三章 我们可能分享的您的信息</h2>
		<p><strong>1.除以下情形外，未经您同意，我们以及我们的关联公司不会分享您的个人信息：</strong></p>
		<p>1.1我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴分享（他们可能并非位于您所在的法域），用作下列用途：</p>
		<ul>
			<li>1.1.1向您提供我们的服务；</li>
			<li>1.1.2实现”我们可能如何使用收集的信息”部分所述目的；</li>
			<li>1.1.3履行我们在本《隐私政策》中的义务和行使我们的权利；</li>
			<li>1.1.4理解、维护和改善我们的服务。</li>
		</ul>
		<p><strong>2.如我们或我们的关联公司分享您的个人信息，我们将努力确保在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。</strong></p>
		<p><strong>3.我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：</strong></p>
		<p>3.1由于部分当地法律法规要求而必须保留的信息；</p>
		<p>3.2遵守法院命令或其他法律程序的规定而必须保留，或披露的信息；</p>
		<p>3.3遵守相关政府机关的要求而必须保留，或披露的信息；</p>
		<p>3.4为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</p>
		<h2>第四章 您可能分享的信息
		</h2>
		<p><strong>1. 您可通过使用我们服务分享信息</strong></p>
		<p>1.1我们的多项服务，可让您不仅与自己的社交网络，也与使用该服务的所有用户公开分享您的相关信息，例如，您在我们的服务中所上传或发布的信息（包括您公开的个人信息、您建立的名单）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。特别是，我们的社交媒体服务，是专为使您与世界各地的用户共享信息而设计，您可以使共享信息实时、广泛地传递。只要您不删除共享信息，有关信息会一直留存在公共领域。
		</p>
		<p>1.2请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从我们的服务中删除您的相关信息，请通过该等特别服务条款提供的方式操作。
		</p>
		<p><strong>2. 您分享的敏感个人信息</strong></p>
		<p>2.1某些个人信息因其特殊性可能被认为是敏感个人信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，敏感个人信息受到更加严格的保护。</p>
		<p>2.2请注意，您在使用我们的服务时所提供、上传或发布的内容和信息（例如有关您社交活动的照片等信息），可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否在使用我们的服务时披露相关敏感个人信息。</p>
		<p>2.3您同意按本《隐私政策》所述的目的和方式来处理您的敏感个人信息。</p>
		<h2>第五章 我们将如何保护您的信息</h2>
		<p><strong>1.
				我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。</strong>
		</p>
		<p><strong>
				2.我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</strong>
		</p>
		<p><strong>3. 我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。</strong></p>
		<h2>第六章 未成年人信息的保护</h2>
		<p><strong>1.我们非常重视对未成年人信息的保护。如果您是未满18周岁的未成年人，您的监护人需要仔细阅读本《隐私政策》并同意或授权您同意依照本《隐私政策》使用我们产品及/或服务。如果您是未满14周岁的未成年人，您的监护人需要仔细阅读本《隐私政策》并明示同意您依照本《隐私政策》使用我们产品及/或服务。</strong>
		</p>
		<p><strong>2. 如果您/您的监护人不同意本《隐私政策》的任何内容，您应该立即停止使用我们产品及/或服务。</strong></p>
		<h2>第七章 本隐私政策的适用范围</h2>
		<p><strong>1.除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定服务的隐私政策。</strong>
		</p>
		<p><strong>2.请您注意，本《隐私政策》不适用于以下情况：</strong></p>
		<p><strong>通过在我们服务中进行广告服务的其他公司或机构所收集的信息.</strong></p>
		<h2>第八章 本隐私政策的变更</h2>
		<p><strong>1.如我们产品及/或服务发生以下变化，我们可能对本《隐私政策》进行相应的修订：</strong></p>
		<p>1.1 我们产品及/或服务所涉业务功能发生变更，导致处理个人信息的目的、类型、使用方式发生变更；</p>
		<p>1.2您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
		<p>1.3我们负责处理您的个人信息安全的部门的联络方式及投诉渠道发生变更；</p>
		<p>1.4发生其他可能影响用户个人信息安全或影响用户隐私权利的变更等。</p>
		<p><strong>2.我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。未经您的明确同意，我们不会削减您基于本《隐私政策》所享有的权利。如该等修订造成您在本《隐私政策》下权利的实质减少，而您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。</strong>
		</p>
		<p><strong>3.如无特殊说明，修订后的《隐私政策》自公布之日起生效。</strong></p>
		<h2>第九章 投诉及建议</h2>
		<p><strong>如您对本《隐私政策》或相关事宜有任何问题，或者对我们服务有什么建议，请直接与我们联系反馈</strong></p>
	</div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../utils/index.js";

export default {
  data() {
    return {
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
      },
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script> 

<style lang="less" scoped>
.privacy {
			background-color: #f5f5f5;
            background-color: #fff;
			margin: 0;
		
		.article {
			margin-top: 36px;
			max-width: 980px;
			padding: 0 15px;
			margin: 0 auto;
			padding-bottom: 50px
		}

		.article h1 {
			font-size: 38px;
			color: #666;
			margin: .67em 0
		}

		.article p {
			font-size: 14px;
			color: #666;
			line-height: 1.68em;
			margin-bottom: 16px
		}

		

		ul,
		li,
		ol {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		.article ul {
			padding-left: 10px;
		}

		.article ul,
		.article li {
			line-height: 1.8em;
			list-style: disc;
			color: #666;
			font-size: 12px;
		}
        }

</style>