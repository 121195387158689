<template>
  <div class="projectList">
    <div class="banner">
      <h3>登记公示</h3>
      <!-- <p>publicity</p> -->
    </div>
    <div class="l-between w1200" style="padding-top: 30px">
      <div class="zdsx">
        <div class="pbox">
          <div class="pblock" v-for="itm in tableDatas.list">
            <div class="title l-nowraptxt" :title="itm.dataName">{{ itm.dataName }}</div>
            <div class="txtbox l-start">
              <!-- <div class="type nowraptxt">登记类型: {{ ["数据要素", "数据产品", "数据资产"][itm.dataType] }}</div> -->
              <div class="name nowraptxt" :title="itm.firmName">登记主体: {{ itm.firmName }}</div>
              <div class="time nowraptxt">
                公示日期: {{ timesFn(itm.publicityStartTime) + " 至 " + timesFn(itm.publicityEndTime) }}
              </div>
            </div>
            <div class="pbtn b" @click="goFn({ name: '公示', url: '/publicityDetails' }, itm)">查看详情</div>
          </div>
        </div>

        <el-empty description="暂无数据" v-if="!tableDatas.list || !tableDatas.list.length" />
        <div class="bombox">
          <el-pagination
            v-if="tableDatas.list && tableDatas.list.length != 0"
            style="padding-right: 20px"
            background
            :page-size="pageSize"
            @current-change="currentChangeFn"
            :current-page.sync="pageNo"
            layout="prev, pager, next , jumper"
            :total="tableDatas.totalRow"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import projectDetails from "./projectDetails";
import { queryDataAnnouncementsAPI } from "@/api/common";
import params from "@/utils/params.js";
export default {
  components: {},
  data() {
    return {
      fileurl: params.appServiceUrldown + "common/fileEcho?fileName=",
      pageSize: 5,
      pageNo: 1,
      tableDatas: {}
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    timesFn(n) {
      return n.substring(0, 10);
    },
    getList() {
      let data = {
        pageNum: this.pageNo,
        pageSize: this.pageSize
      };
      queryDataAnnouncementsAPI(data)
        .then(res => {
          if (res.code == 0) {
            this.tableDatas = res.data;
          } else {
          }
        })
        .catch(error => {});
    },
    goFn(itm, key) {
      let data = { title: itm.name, id: key.dataId };
      let url = itm.url;

      this.$router.push({
        path: url,
        query: data
      });
    },
    currentChangeFn(val) {
      this.pageNo = val;
      this.getList();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.banner {
  width: 100%;
  height: 270px;
  background: url(../../assets/img/news_1.png) no-repeat center center;
  background-size: 1920px 270px;

  h3 {
    width: 578px;
    height: 45px;
    font-size: 36px;
    color: #ffffff;
    line-height: 45px;
    width: 1180px;
    font-weight: 400;
    margin: 0px auto;
    position: relative;
    top: 112px;
  }
  p {
    height: 19px;
    font-size: 16px;
    color: #fff;
    line-height: 19px;
    width: 1180px;
    margin: 0px auto;
    position: relative;
    top: 125px;
  }
}
.projectList {
  padding-bottom: 50px;
  padding-top: 70px;
  height: 100%;
  min-height: 844px;
  .zdsx {
    width: 1225px;

    .bombox {
      text-align: center;
      margin-top: 57px;
      margin-bottom: 50px;
    }
  }
}
.zdsx-block:hover {
  h3 {
    color: #3e76e0 !important;
  }
}

.pbox {
  width: 1227px;
  margin: 0 auto;

  .pblock {
    width: 1227px;
    height: 116px;
    background: #ffffff;
    box-shadow: 0px 0px 18px 14px rgba(19, 40, 64, 0.04);
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 22px 24px;
    margin-bottom: 12px;
    position: relative;
    .title {
      height: 28px;
      font-family: MicrosoftYaHei;
      font-size: 18px;
      color: #2f354d;
      line-height: 28px;
      text-align: left;
      font-style: normal;
    }
    .txtbox {
      margin-top: 13px;
      color: #82869d;
      .type {
        width: 160px;
        margin-right: 20px;
      }
      .name {
        width: 460px;
        margin-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .time {
        width: 530px;
      }
    }
    .pbtn {
      width: 92px;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #2e6bff;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #2e6bff;
      line-height: 36px;
      letter-spacing: 1px;
      text-align: center;
      font-style: normal;
      position: absolute;
      right: 31px;
      top: 40px;
    }
  }
}
</style>
