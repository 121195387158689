import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import getters from './getters'
import user from './modules/user'
import msg from './modules/msg'
import global from './modules/global'
export default new Vuex.Store({
  modules: {
    global,
    user,
    msg
  },
  getters
})
