<template>
  <div v-loading="loading" element-loading-background="#fff" >
    <ul class="importantDataList">
      <li class="importantData-item" v-for="(item, index) in importantDataList" :key="index">
        <div class="name">{{ item.name }}</div>
        <img :src="item.icon" class="dataIcon" />
        <div class="data" v-if="item.key !== 'drs'">{{ detail[item.key] || "--" }}</div>
        <div class="data" v-else>{{ formatNumberThousands(detail[item.key] || 0) }}</div>
      </li>
    </ul>
    <div class="detailList">
      <el-descriptions :column="1" border>
        <el-descriptions-item label="行业">{{ industryStr || "" }}</el-descriptions-item>
        <el-descriptions-item label="数据起止时间">{{
          (detail.startDate || "") + " 至 " + (detail.endDate || "")
        }}</el-descriptions-item>
        <el-descriptions-item label="数据覆盖区域">
          <el-tag v-for="(item, index) in detail.dataScopeList" :key="index" size="small" type="info" style="margin-right: 10px">
            {{ getAreaName(item) }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="数据关键字">
          <el-tag v-for="(item, index) in keywordsList" :key="index" size="small" type="primary" style="margin-right: 10px">
            {{ item }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="数据样例">
          <el-button type="text" @click="handlePreview" v-if="this.detail.sample">点击查看</el-button>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="productDescription">
      <div class="title">数据产品说明</div>
      <richTextViewer :content="detail.content" />
    </div>
  </div>
</template>
<script>
import { getDataDetail } from "@/api/dataPresentation";
import { openFile, formatNumberThousands } from "@/utils";
import richTextViewer from "@/views/components/richTextViewer";
export default {
  name: "DataProductIntroduction",
  components: {
    richTextViewer
  },
  props: {
    baseInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading:false,
      importantDataList: [
        { name: "登记节点", icon: require("@/assets/img/dataIcon_3.png"), key: "nodeName" },
        { name: "登记主体", icon: require("@/assets/img/dataIcon_5.png"), key: "firmName" },
        { name: "发布时间", icon: require("@/assets/img/dataIcon_4.png"), key: "shelfTime" },
        { name: "数据量(DRs)", icon: require("@/assets/img/dataIcon_1.png"), key: "drs" }
      ],
      detail: {}
    };
  },
  computed: {
    industryStr() {
      let keyArr = ["industryClassAName", "industryClassBName", "industryClassCName", "industryClassDName"];
      let valArr = keyArr.map(key => this.detail[key]).filter(item => item);
      return valArr.join(" > ");
    },
    keywordsList() {
      if (!this.detail.keywords) {
        return [];
      } else {
        return this.detail.keywords.split(",");
      }
    }
  },
  mounted() {
    this.getDataDetail();
  },
  methods: {
    formatNumberThousands,
    async getDataDetail() {
      try {
        this.loading = true
        let res = await getDataDetail(this.baseInfo.dataId);
        this.loading = false
        if (res.code === 0) {
          let { content } = res.data;
          content = content || "";
          content = content.replace(/\/[^<>\/]+\/adminFile\//g, `${process.env.VUE_APP_API_BASE_URL}/adminFile/`);
          this.detail = {
            ...res.data,
            content
          };
        }
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    getAreaName(item) {
      let keyArr = ["provinceName", "cityName", "countyName"];
      return keyArr
        .map(key => item[key])
        .filter(item => item)
        .join(" / ");
    },
    handlePreview() {
      openFile(this.detail.sample);
    }
  }
};
</script>
<style lang="less" scoped>
.importantDataList {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border: 1px solid #e4e7ed88;
  padding: 20px;
  display: flex;
  margin-bottom: 40px;
  border-radius: 8px;
  .importantData-item {
    font-family: MicrosoftYaHei;
    text-align: center;
    font-style: normal;
    flex: 1;
    margin: 0 10px;

    .name {
      font-size: 12px;
      color: #8f9399;
      margin-bottom: 10px;
    }

    .dataIcon {
      width: 40px;
      height: 40px;
    }

    .data {
      font-size: 14px;
      color: #383f49;
      margin-top: 10px;
    }
  }
}

.detailList {
  ::v-deep .el-descriptions-item__label {
    width: 250px;
  }
}

.productDescription {
  margin-top: 40px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #626770;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  .title {
    font-size: 16px;
    color: #303133;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>
