import request from "@/api/request";
//根据字典名称查询字典
export const queryDict = (dictType) => {
    return request({
      url: `/common/getCommonCodesByType/${dictType}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
};
