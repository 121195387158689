import { getToken, setToken, removeToken, toLogin } from "@/utils/auth.js";
import router from "@/router";
import { Message } from "element-ui";
const user = {
  state: {
    msgNum: 0
  },
  mutations: {
    SAVE_MSGNUM(state, info) {
      state.msgNum = info;
    },
    RESET_MSGNUM(state) {
      state.msgNum = 0;
    }
  },
  actions: {
    //获取用户信息
    async getUserInfo({ commit }) {
      //   try {
      //     let res = await getUserInfoApi();
      //     if (res.code === 0) {
      //       commit("SAVE_MSGNUM", res.data);
      //     } else {
      //       return Promise.reject(res.data);
      //     }
      //   } catch (error) {
      //     return Promise.reject(error);
      //   }
    }
  }
};

export default user;
