<template>
  <div class="projectList">
    <div class="banner">
      <h3>新闻资讯</h3>
      <!-- <p>为您提供工业数据资产登记平台相关的新闻动态</p> -->
    </div>
    <div class="l-between w1200" style="padding-top: 50px">
      <!-- <div class="nav">
          <img class='navimg' src='~@/assets/img/news_2.png'>
          <div class="navl nav2 nav1 b" :class="{ navtive: !isnav }" @click="nav(1,6)" v-if='isnews1'>
            <span class='el-icon-notebook-2'></span>
            行业动态
          </div>
          <div class="navl nav2  b" :class="{ navtive: isnav }" @click="nav(2,7)" v-if='isnews2'>
            <span class='el-icon-office-building'></span>
            公司动态
          </div>
        </div> -->
      <div class="zdsx" v-show="!isprojectDetails">
        <div
          class="zdsx-block border-radius-primary l-between b"
          v-for="(itm, index) in tableDatas.list"
          :key="index"
          @click="detailsFn(itm)"
        >
          <div class="cropping border-radius-primary">
            <img class="zdsx-block-left" :src="imgFn(itm.titlePhoto)" />
          </div>
          <div class="zdsx-block-cen">
            <h3 class="l-nowraptxt title" :title="itm.titlePrimary">
              {{ itm.titlePrimary }}
            </h3>
            <p :title="itm.briefIntroduction">{{ itm.briefIntroduction }}</p>
            <div class="all">
              <div class="time"><span style="margin-right: 3px" class="el-icon-time"></span>{{ itm.createTime }}</div>
              <!-- <div class="play">游览量:{{ itm.views }}</div> -->
            </div>
          </div>
        </div>
        <div v-if="tableDatas.list.length == 0" style="margin-top: 50px; text-align: center; color: #999">暂无数据</div>
        <div class="bombox" v-if="tableDatas.list.length != 0">
          <el-pagination
            style="padding-right: 20px"
            background
            :page-size="pageSize"
            @current-change="currentChangeFn"
            :current-page.sync="pageNo"
            layout="prev, pager, next , jumper"
            :total="tableDatas.totalRow"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <projectDetails :datas='dataDetails' isshow='isprojectDetails' v-show='isprojectDetails'></projectDetails> -->
  </div>
</template>

<script>
// import projectDetails from "./projectDetails";
import { selectTitleAPI } from "@/api/common";
import params from "@/utils/params.js";
export default {
  components: {},
  data() {
    return {
      isprojectDetails: false,
      fileurl: params.appServiceUrldown + "common/fileEcho?fileName=",
      pageSize: 6,
      pageNo: 1,
      seachdata: {},
      tableDatas: {},
      dataDetails: {},
      id: "",
      isnav: false,
      ids: 1,
      titleType: 4,
      isnews1: false,
      isnews2: false
    };
  },
  mounted() {
    // this.getList2(6)
    // this.getList2(7)
    // if(this.$route.query.titleType){
    //    this.pageNo= this.$route.query.pageNo,
    //   this.nav(this.$route.query.ids,this.$route.query.titleType,'k')
    // }else{

    // }
    this.getList();
  },
  methods: {
    getList2(id) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        titleType: id
      };
      selectTitleAPI(data)
        .then(res => {
          if (res.code == 0) {
            if (res.data.records.length > 0) {
              if (id == 6) {
                this.isnews1 = true;
              } else {
                this.isnews2 = true;
              }
            }
          } else {
          }
        })
        .catch(error => {});
    },
    nav(t, id, k) {
      if (!k) this.pageNo = 1;
      this.ids = t;
      this.isnav = id == 6 ? false : true;
      this.titleType = id;
      this.getList();
    },
    imgFn(n) {
      let k = require("@/assets/img/news_img.png");
      return n ? process.env.VUE_APP_API_BASE_URL + "/adminFile/down/" + n : k;
    },
    detailsFn(itm) {
      this.dataDetails = itm;
      // this.isprojectDetails=true
      //  sessionStorage.setItem("isshow","是")
      // sessionStorage.setItem("dataDetails", JSON.stringify(itm));

      this.$router.push({
        path: "/newsDetails",
        query: {
          titleId: itm.titleId
        }
      });
    },
    currentChangeFn(val) {
      this.pageNo = val;
      this.getList();
    },
    getList(form) {
      let data = {
        pageNum: this.pageNo,
        pageSize: this.pageSize,
        titleType: 50
      };
      // if (form) {
      //   data = form;
      //   this.seachdata = data;
      // } else {
      //   data = this.seachdata;
      // }

      selectTitleAPI(data)
        .then(res => {
          if (res.code == 0) {
            this.tableDatas = res.data;
          } else {
          }
        })
        .catch(error => {});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.banner {
  width: 100%;
  height: 270px;
  background: url(../../assets/img/news_1.png) no-repeat center center;
  background-size: 1920px 270px;

  h3 {
    width: 578px;
    height: 45px;
    font-size: 36px;
    color: #ffffff;
    line-height: 45px;
    width: 1180px;
    font-weight: 400;
    margin: 0px auto;
    position: relative;
    top: 112px;
  }
  p {
    height: 19px;
    font-size: 16px;
    color: #fff;
    line-height: 19px;
    width: 1180px;
    margin: 0px auto;
    position: relative;
    top: 125px;
  }
}
.projectList {
  padding-bottom: 50px;
  padding-top: 70px;
  height: 100%;
  min-height: 844px;
  .zdsx {
    width: 1225px;
    @blockHeight: 186px;
    .zdsx-block {
      width: 1225px;
      height: @blockHeight;
      background: #fff;
      margin-bottom: 26px;
      padding: 10px;
      transition: all 0.5s;
      &:hover {
        .zdsx-block-left {
          transform: scale(1.2);
          transition: all 200ms linear;
        }
      }
      .cropping {
        overflow: hidden;
        width: 1.5 * @blockHeight;
        height: @blockHeight - 20px;
        .zdsx-block-left {
          width: 1.5 * @blockHeight;
          height: @blockHeight - 20px;
          object-fit: cover;
          transition: all 200ms linear;
        }
      }

      .zdsx-block-cen {
        flex: 1;
        padding: 0 20px;
        position: relative;
        .title {
          margin-top: 5px;
          max-width: 829px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #272729;
          line-height: 29px;
          font-weight: 400;
        }
        p {
          color: #999999;
          line-height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-width: 829px;
          margin-top: 13px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #8f8f8f;
          line-height: 24px;
        }
        .all {
          display: flex;
          justify-content: space-between;
          .play {
            margin-top: 58px;
            height: 19px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #aaaaaa;
            line-height: 19px;
          }
          .time {
            height: 19px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #aaaaaa;
            line-height: 19px;
            position: absolute;
            bottom: 5px;
            left: 20px;
          }
          .play {
            margin-left: 30px;
            flex: 1;
          }
        }

        .xqbtn {
          border: 1px solid #bababa;
          text-align: center;
          font-size: 14px;
          color: #616161;
          width: 113px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          position: absolute;
          bottom: 30px;
          right: 50px;
        }
        .xqbtn:hover {
          color: #fff;
          background: #1ba2f3;
        }
      }
      .zdsx-block-rig {
        width: 162px;
        height: 100%;
        position: relative;
        .zdsx-btn {
          width: 34px;
          height: 35px;
          // background: url("~@/assets/images/projectData_4.png") no-repeat
          //   center center;
          background-size: 100% 100%;
          margin: 89px 0 0 55px;
        }
        .zdsx-btn:hover {
          // background: url("~@/assets/images/projectData_3.png") no-repeat
          // center center;
          background-size: 100% 100%;
        }
        .xian {
          position: absolute;
          left: 0;
          top: 14px;
          width: 1px;
          height: 186px;
          background: rgba(112, 112, 112, 0.12);
        }
      }
    }
    .zdsx-block:hover {
      background: #ffffff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.07);
    }
    .bombox {
      text-align: center;
      margin-top: 57px;
      margin-bottom: 30px;
    }
  }
}
.zdsx-block:hover {
  h3 {
    color: #3e76e0 !important;
  }
}
</style>
<style lang="less">
.projectList {
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    background-color: #fff;
    color: #606266;
    min-width: 30px;
    border-radius: 2px;
  }
}
.nav {
  min-width: 187px;
  position: relative;
  .navimg {
    position: absolute;
    right: -17px;
    top: -10px;
    z-index: 0;
  }
  .navl {
    width: 187px;
    height: 73px;
    background: #f6f9fc;
    text-align: center;
    line-height: 73px;
    border-right: 6px solid #f6f9fc;
    font-weight: 500;
    color: #9d9b9b;
    font-size: 18px;
    z-index: 1;
    position: relative;
    span {
      font-size: 22px;
      position: relative;
      top: 1px;
    }
  }
  .nav1 {
    span {
      font-size: 20px;
    }
  }
  .navtive {
    border-right: 6px solid #4c79e5;
    color: #4c79e5;
    background: #e5ecfd;
  }
}
</style>
