import { getNodeConfig } from "@/api/dataPresentation";
const user = {
  state: {
    globalConfig: {}
  },
  mutations: {
    SAVE_GLOBAL_CONFIG(state, data) {
      state.globalConfig = data;
    }
  },
  actions: {
    async getGlobalConfig({ commit }) {
      try {
        let res = await getNodeConfig();
        if (res.code === 0) {
          commit("SAVE_GLOBAL_CONFIG", res.data);

          // 先移除旧的link标签
          var oldLink = document.querySelector("link[rel~='icon']");
          if (oldLink) {
            document.head.removeChild(oldLink);
          }

          // 创建新的link标签
          var newLink = document.createElement("link");
          newLink.rel = "icon";
          newLink.href = res.data.iconLogo;

          // 添加到head中
          document.head.appendChild(newLink);

          // 使用示例：将favicon改变为指定的图片地址
          // changeFavicon("https://example.com/new_favicon.ico");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};

export default user;
