<template>
    <div class="home">
      <img class="head head1"  :class="{none:activeIndexId!=0}" src="@/assets/demoimg/7.png">
      <img class="head head2 "  :class="{none:activeIndexId==0}" src="@/assets/demoimg/6.png">
    
        <div class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(itm, index) in bNavList2" :key="index">
              <img style="width: 100%;" :src="itm.img" alt="" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
   
  
  
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { isElementNotInViewport } from "../utils/index.js";
  import {
    selectTitleAPI,
    queryDataAnnouncementsAPI,
    queryDataDynamicsAPI,
    nodeConditionVOAPI,
    nationConditionVOAPI,
    selectBannerAPI
  } from "@/api/common";
  import homeDialog from "./components/homeDialog";
  import params from "@/utils/params.js";
  import { mapGetters } from "vuex";
  // import newsimg from "@/assets/img/news_img.png";
  
  export default {
    name: "Home",
    components: {
      homeDialog
    },
    data() {
      return {
        isCity: process.env.VUE_APP_APPTYPE == "国家" ? false : true,
        isDialog: false,
        idx: 0,
        input: "",
        activeIndexId:0,
        datas: {},
        bNavList2: [
          { title: "城市节点数", key: "nodeTotal", unit: "个", img: require("@/assets/demoimg/1.png") },
          { title: "注册企业数量", key: "firmsTotal", unit: "家", img: require("@/assets/demoimg/2.png") },
          { title: "颁发证书数", key: "certiTotal", unit: "个", img: require("@/assets/demoimg/3.png") },
          { title: "登记数据量", key: "dreTotal", unitkey: "dreTotalUnit", img: require("@/assets/demoimg/4.png") },
          { title: "登记数据量", key: "dreTotal2", unitkey: "dreTotalUnit2", img: require("@/assets/demoimg/5.png")} 
        ],
        newsList: [],
      
         
        scrollPosition: 0,
        bannerTop: [],
        swiper: null
      };
    },
    computed: {
      ...mapGetters(["loginUserInfo"])
    },
    mounted() {
      let that = this;
      this.swiperFn();
    },
    methods: {
      timesFn(n) {
        return n.substring(0, 10);
      },
      imgFn(p) {
        let k = require("@/assets/img/news_img.png"); //newsimg;
        return p ? process.env.VUE_APP_API_BASE_URL + "/adminFile/down/" + p : k;
      },
      closeFn() {
        this.isDialog = false;
      },
 
    
  
  
  
      swiperFn() {
        let that = this;
        this.swiper = new Swiper(".mySwiper", {
          slidesPerView: 1,
          spaceBetween: 30,
          spaceBetween: 0,
          mousewheel: true,
          direction: 'vertical',
          // loop: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          on: {
            slideChange: function () {
              console.log("当前激活的幻灯片索引:", this.activeIndex);
              that.activeIndexId=this.activeIndex
            }
          } 
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev"
          // }
        });
      }
    }
  };
  </script>
  <style lang="less" scoped>
  .home {
    background: #fff;
    width:100%;
    height:100%;
    margin-top: -70px;
  }
  .head{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:70px;
    z-index: 99;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
  .mySwiper{
  width: 100%;
  height: 100vh;
}

.swiper-pagination{
  padding:2px 5px;
  background:#858a98;
  border-radius: 10px;
}
:deep(.swiper-pagination-bullet){
  width:15px !important;
  height:15px !important;
  background:#fff !important ; 
  opacity: 1;
  margin:10px 0 !important;
}
:deep(.swiper-pagination-bullet-active){
  background:#007aff !important
} 

.none{
  /* display: none; */
  opacity: 0;
}
.head {
  transition: opacity 0.5s; /* 渐变过渡效果 */
}

.head.none {
  opacity: 0; /* 渐隐 */
  pointer-events: none; /* 禁止点击 */
}

.head:not(.none) {
  opacity: 1; /* 渐现 */
  pointer-events: auto; /* 允许点击 */
}
 


  </style>
  
  <style lang="less" scoped></style>
  